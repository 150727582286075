import { Vermittler } from './api-types';
import STIClient from "./STIClient";

const VERMITTLER_REST_BASE_URL = '/vermittler';

class VermittlerService {
    async create(vermittler: Vermittler): Promise<Vermittler> {
        const res = await STIClient.post(VERMITTLER_REST_BASE_URL, vermittler);
        return res.data;
    }

    async get(kunde: number, mandant: number): Promise<Vermittler> {
        const res = await STIClient.get(VERMITTLER_REST_BASE_URL + '/?kunde=' + kunde + '&mandant=' + mandant);
        return res.data;
    }

    async update(id: { kunde: number, mandant: number }, vermittler: Vermittler): Promise<Vermittler> {
        const res = await STIClient.put(VERMITTLER_REST_BASE_URL, {
            id: id,
            vermittler: vermittler
        });
        return res.data
    }

    async delete(id: { kunde: number, mandant: number }) {
        return await STIClient.delete(VERMITTLER_REST_BASE_URL, { data: id });
    }

}
const vermittlerService = new VermittlerService();

export default vermittlerService;
