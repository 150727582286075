import STIClient from "./STIClient";

const BENUTZER_REST_BASE_URL = '/benutzer';

class BenutzerService {

    async getUserlist(username: string, signal: AbortSignal): Promise<string[]> {
        const res = await STIClient.get(BENUTZER_REST_BASE_URL + '/list?username=' + username);
        return res.data;
    }

}

const benutzerService = new BenutzerService();

export default benutzerService;