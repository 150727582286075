import { Alert, Button, Col, Divider, Form, Input, Layout, Row, Select } from 'antd';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchInput from '../../components/Form/SearchInput';
import Topbar from '../../components/Topbar/Topbar';
import AuthencationService from '../../services/AuthencationService';
import BenutzerService from '../../services/BenutzerService';
import TokenService from '../../services/TokenService';
import { handleBeforeunloadEvent, noText } from '../../utils/ts_helpers';
import usePageHistory from '../../hooks/usePageHistory';

interface LocationState {
    from?: { pathname: string },
};


const Login = () => {

    const pageHistory = usePageHistory();

    window.removeEventListener('beforeunload', handleBeforeunloadEvent);

    const { Content } = Layout;
    const { Option } = Select;

    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as LocationState;
    const from = state ? state.from?.pathname || "/start" : "/start";

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const getUserlist = (username: string, signal: AbortSignal) => {
        return BenutzerService.getUserlist(username, signal);
    };

    const handleLogin = () => {
        if (noText(username)) {
            setErrorMessage('Bitte Benutzer eingeben.');
            return;
        }
        if (noText(password)) {
            setErrorMessage('Bitte Passwort eingeben.');
            return;
        }
        AuthencationService.login(username, password).then(response => {
            TokenService.setAuth(response);
            setUsername('');
            setPassword('');
            navigate(from, { state: { from: location }, replace: true });
            window.removeEventListener('beforeunload', handleBeforeunloadEvent);
            window.location.reload();
        }).catch(error => {
            if (!error?.response) {
                setErrorMessage('No Server Response');
            } else if (error.response?.status === 400) {
                setErrorMessage('Missing Username or Password');
            } else if (error.response?.status === 401) {
                setErrorMessage('Unauthorized');
            } else {
                setErrorMessage('Login Failed');
            }
        });
    }

    return (
        <Layout>
            <Topbar></Topbar>
            <Content>
                <Row justify={'center'} align={'middle'}>
                    <Col style={{
                        padding: 20,
                        boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)',
                        margin: 20
                    }} span={8}>
                        <Form layout='vertical' title='SmartApp Login'>
                            <Divider>SmartApp Anmeldung</Divider>
                            {errorMessage ? <Row>
                                <Col span={24}>
                                    <Alert type="error" message={errorMessage} aria-live="assertive"></Alert>
                                </Col>
                            </Row> : <></>}
                            <Row>
                                <Col span={24}>
                                    <Form.Item label='Benutzername'>
                                        <SearchInput<string>
                                            onValueChange={value => setUsername(value ?? '')}
                                            fetchDataRequest={getUserlist}
                                            render={value => <>{value}</>} 
                                            labelFormatter={value => value}                                        >
                                        </SearchInput>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label='Password'>
                                        <Input type='password'
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                            onPressEnter={handleLogin}
                                        >
                                        </Input>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={16} offset={8}>
                                    <Button type='primary' onClick={handleLogin}>Anmelden</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

export default Login;
