import STIClient from "./STIClient";
import TokenService, { Auth } from "./TokenService";

const AUTHENCATION_REST_BASE_URL = '/auth';
const AUTHENCATION_REST_REFRESH_URL = '/refresh';

export interface RefreshToken {
    token: string;
}

export const login = async (username: string, password: string): Promise<Auth> => {
    const res = await STIClient.post(AUTHENCATION_REST_BASE_URL, JSON.stringify({ username, password }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        });
    return res.data;
}

export const refresh = async (): Promise<RefreshToken> => {
    const res = await STIClient.post(AUTHENCATION_REST_REFRESH_URL, undefined, { withCredentials: true });
    return res.data;
}

export const logout = () => {
    TokenService.removeUser();
}

const AuthencationService = {
    login,
    refresh,
    logout,
};

export default AuthencationService;