import STIClient from "./STIClient";
import { AppMetadata } from "./api-types";

const keys: Array<keyof AppMetadata> = ["app.name", "app.profile", "app.version"];

export const appMetadata = async (): Promise<AppMetadata> => {
    const res = await STIClient.get('/about', { headers: { 'Content-Type': 'application/json' } });
    return Object.fromEntries(keys.map<[keyof AppMetadata, string]>(k => [k, `${res.data[k]}`]))
}

const AppService = {
    appMetadata,
};

export default AppService;
