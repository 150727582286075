import { TablePaginationConfig } from "antd";
import STIClient from "./STIClient";
import { Zaehlerstanderfassung, ZaehlerstanderfassungView, PageType, Sorter, VertragsKost, Netzentgelte, NetzentgeltDTO } from "./api-types";
import { isPresent } from "../utils/ts_helpers";

const ZAEHLERSTANDERFASSUNG_REST_BASE_URL = '/zaehlerstanderfassung';

class ZaehlerstanderfassungService {

    async getAllZaehlerstanderfassungen(): Promise<Zaehlerstanderfassung[]> {
        const res = await STIClient.get(ZAEHLERSTANDERFASSUNG_REST_BASE_URL + '/all', {
            method: 'GET',
        });
        return res.data;
    }

    async createZaehlerstanderfassung(zaehlerstanderfassung: Zaehlerstanderfassung, angebotId?: number): Promise<Zaehlerstanderfassung> {
        const url = angebotId ? ZAEHLERSTANDERFASSUNG_REST_BASE_URL + '/' + angebotId : ZAEHLERSTANDERFASSUNG_REST_BASE_URL;
        const res = await STIClient.post(url, zaehlerstanderfassung);
        return res.data;
    }

    async updateZaehlerstanderfassung(zaehlerstanderfassung: Zaehlerstanderfassung): Promise<Zaehlerstanderfassung> {
        const res = await STIClient.put(ZAEHLERSTANDERFASSUNG_REST_BASE_URL + '/' + zaehlerstanderfassung.id, zaehlerstanderfassung);
        return res.data
    }

    async getZaehlerstanderfassung(id: string): Promise<Zaehlerstanderfassung> {
        const res = await STIClient.get(ZAEHLERSTANDERFASSUNG_REST_BASE_URL + '/' + id);
        return res.data;
    }

    async deleteZaehlerstanderfassung(id: number): Promise<void> {
        const res = await STIClient.delete(ZAEHLERSTANDERFASSUNG_REST_BASE_URL + '/' + id);
        return res.data;
    }

    async getVertragKost(key: string, signal: AbortSignal): Promise<VertragsKost[]> {
        const res = await STIClient.get(ZAEHLERSTANDERFASSUNG_REST_BASE_URL + '/vertragskost?tarifname=' + key, {
            signal: signal,
        });
        return res.data;
    }

    async getPageableOffers(params: { [key: string]: string | string[] }, columns: Array<string>, pagination: TablePaginationConfig, sorters: Sorter[], signal: AbortSignal): Promise<PageType<ZaehlerstanderfassungView>> {
        const res = await STIClient.get(ZAEHLERSTANDERFASSUNG_REST_BASE_URL + '/globalSearch', {
            params: { ...params, columns: columns.filter(s => isPresent(s) && s !== 'Energieart').map(s => s.split(' ').map((s,i) => 
            i === 0 ? s.toLowerCase(): s.toLowerCase().charAt(0).toUpperCase() + s.toLowerCase().slice(1)).join('')).join(','), currentPage: pagination.current ?? 0, pageSize: pagination.pageSize, sorters: sorters },
            paramsSerializer: params => {
                return Object.keys(params)
                    .map(key => ['sorters'].includes(key) ?
                        `${key}=${encodeURIComponent(JSON.stringify(params[key]))}` :
                        Array.isArray(params[key]) && params[key].length > 0 ?
                            params[key]?.map((p: number) => `${key}=${encodeURIComponent(p)}`).join('&') : `${key}=${encodeURIComponent(params[key])}`).join('&');
            },
            signal: signal
        });
        return res.data;
    }

    async getZaehlerstanderfassungView(id: string): Promise<Array<ZaehlerstanderfassungView>> {
        const res = await STIClient.get(ZAEHLERSTANDERFASSUNG_REST_BASE_URL + '/views/' + id);
        return res.data;
    }

    async getNetzentgelteByPlz(plz: string, gas: boolean): Promise<Array<Netzentgelte>> {
        const res = await STIClient.get(ZAEHLERSTANDERFASSUNG_REST_BASE_URL + (gas ? '/gas': '') + '/plz/' + plz);
        return res.data;
    }

    async getNetzentgeltDTOByYear(year: number, gas: boolean): Promise<Array<NetzentgeltDTO>> {
        const res = await STIClient.get(ZAEHLERSTANDERFASSUNG_REST_BASE_URL + (gas ? '/gas': '') + '/year/' + year);
        return res.data;
    }
}

const zaehlerstanderfassungService = new ZaehlerstanderfassungService();

export default zaehlerstanderfassungService;
