import { DeleteOutlined, EditOutlined, MenuOutlined, SendOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Dropdown, Image, Input, MenuProps, Modal, Row, Table, TableProps } from 'antd';
import { ColumnGroupType, ColumnType, ColumnsType, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageType, Sorter } from '../../services/api-types';
import { handleErrorResponse, isPresent } from '../../utils/ts_helpers';

export interface ColumnTypeStringKey<T> extends Omit<(ColumnType<T> | ColumnGroupType<T>), 'key'> {
    key: string;
    children?: ColumnsType<T>;
    dataIndex?: string;
}

interface SearchTableConfiguration<T> extends Omit<TableProps<T>, 'representColumns' | 'request' | 'message' | 'confirmDelete'> {
    columns: ColumnTypeStringKey<T>[];
    representColumns: string[];
    setRepresentColumns: React.Dispatch<React.SetStateAction<string[]>>;
    globalSearch?: boolean;
    globalSearchPlaceHolder?: string;
    params: { [key: string]: string | string[] };
    onParamsChange: (key: string, value: string) => void;
    resetParams?: () => void;
    request: (params: { [key: string]: string | string[] }, columns: Array<string>, pagination: TablePaginationConfig, Sorters: Sorter[], signal: AbortSignal) => Promise<PageType<T>>;
    message?: (value?: T) => string;
    confirmDelete?: (value?: T) => Promise<void>;
    handleEditRecord: (value: T) => void;
    exportData?: (list: Array<T>) => Array<string>;
    redirect?: boolean;
    generateRedirectLink?: (list: Array<T>) => string;
    headers?: Array<string>;
    data: T[];
    setData: React.Dispatch<React.SetStateAction<T[]>>;
    actionConfig?: ColumnTypeStringKey<T>;
    toolbar?: ReactNode;
    hideDelete?: boolean;
}

function SearchTable<T extends Omit<Object, 'id'>>({ columns,
    representColumns,
    setRepresentColumns,
    globalSearch,
    globalSearchPlaceHolder,
    params,
    onParamsChange,
    resetParams,
    request,
    message,
    confirmDelete,
    handleEditRecord,
    exportData,
    redirect,
    generateRedirectLink,
    headers,
    data,
    setData,
    actionConfig,
    toolbar,
    hideDelete,
    ...rest }: SearchTableConfiguration<T>) {

    const navigate = useNavigate();

    const [representedColumnsDropdownOpen, setRepresentedColumnsDropdownOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);

    const [value, setValue] = useState<T>();

    const [exportList, setExportList] = useState<Array<T>>([]);

    const handleAlertOpen = (record: T) => {
        setAlertOpen(true);
        setValue(record);
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(1);

    const [sorterResult, setSorterResult] = useState<SorterResult<T> | SorterResult<T>[]>([]);

    const getSorters = (): Sorter[] => {
        if (Array.isArray(sorterResult)) {
            let newSorters: Sorter[] = [];
            sorterResult.forEach(s => {
                if (s.order && s.columnKey) {
                    newSorters.push({ column: s.columnKey.toString(), order: s.order });
                }
            });
            return newSorters;
        } else {
            if (sorterResult.order && sorterResult.columnKey) {
                return [{ column: sorterResult.columnKey.toString(), order: sorterResult.order }];
            } else {
                return [];
            }
        }
    };

    useEffect(() => {
        const controller = new AbortController();

        const fetchData = async () => {
            try {
                setLoading(true);
                const sorters = getSorters();
                const pagination = { current: currentPage - 1, pageSize: pageSize };
                const result = await request(params, representColumns, pagination, sorters, controller.signal);
                setData(result.content);
                setTotal(result.totalElements);
                setCurrentPage(result.pageable.pageNumber + 1);
            } catch (error) {
                handleErrorResponse(error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();

        return () => controller.abort();
    }, [params, currentPage, pageSize, sorterResult, data.length]);

    const menuProps: MenuProps = {
        selectedKeys: representColumns,
        onSelect: ({ selectedKeys }) => setRepresentColumns(selectedKeys),
        onDeselect: ({ selectedKeys }) => setRepresentColumns(selectedKeys),
        items: columns.map(c => ({ key: c.key, label: c.key })),
        selectable: true,
        multiple: true,
    };

    const generateCSV = () => {
        const separator = ';';
        const content = '\ufeff' + headers?.join(separator) + '\n'
            + (
                exportData ?
                    exportData(exportList).join('\n') : ''
            );
        const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'angebot_' + new Date().toLocaleDateString() + '_' + new Date().toLocaleTimeString() + '.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const action: ColumnTypeStringKey<T> = actionConfig ?? {
        title: 'Aktion',
        key: 'aktion',
        children: [
            {
                key: 0,
                title:
                    <Row gutter={[8, 8]} wrap={false}>
                        <Col><Button title="zurücksetzen" onClick={resetParams} icon={<Image height={16} preview={false} src='/images/filter-remove-icon.svg'></Image>}></Button></Col>
                        <Col>
                            <Dropdown open={representedColumnsDropdownOpen} menu={menuProps} trigger={['click']}>
                                <Button title="Filter Spalten" icon={<MenuOutlined></MenuOutlined>} onClick={e => { e.preventDefault(); setRepresentedColumnsDropdownOpen(!representedColumnsDropdownOpen); }}></Button>
                            </Dropdown>
                        </Col>
                        <Col hidden={!exportList.length}><Button danger icon={<UploadOutlined />} onClick={() => generateCSV()}></Button></Col>
                        <Col hidden={redirect ? !exportList.length : true}><Button style={{ color: 'orange', borderColor: 'orange' }} icon={<SendOutlined />}
                            onClick={() => {
                                if (redirect && generateRedirectLink) {
                                    const link = generateRedirectLink(exportList);
                                    navigate(link);
                                }
                            }}
                        ></Button></Col>
                    </Row>,
                render: (_: unknown, record: T, index: number) =>
                    <Row justify={'center'} align={'middle'} gutter={[8, 8]} wrap={false}>
                        <Col><Button title="bearbeiten" type="primary" ghost icon={<EditOutlined />} onClick={() => handleEditRecord(record)} /></Col>
                        <Col><Button title="löschen" hidden={hideDelete} danger icon={<DeleteOutlined />} onClick={() => handleAlertOpen(record)} /></Col>
                        <Col hidden={!isPresent(exportData)}><Checkbox onChange={e => {
                            if (e.target.checked) {
                                setExportList(prev => {
                                    return [...prev, record];
                                });
                            } else {
                                setExportList(prev => {
                                    return prev.filter(e => e !== record);
                                });
                            }
                        }}></Checkbox></Col>
                    </Row>
            }],
    };

    return (
        <>
            <Modal
                destroyOnClose={true}
                open={alertOpen}
                maskClosable={false}
                okButtonProps={{ danger: true }}
                title="Löschen bestätigen?"
                onOk={() => {
                    if (confirmDelete) {
                        confirmDelete(value);
                    }
                    handleAlertClose();
                }}
                okText={'Bestätigen'}
                onCancel={handleAlertClose}>
                <p>{message ? message(value) : ''}</p>
            </Modal>
            {toolbar ?? <></>}
            <Input hidden={!globalSearch}
                placeholder={globalSearchPlaceHolder}
                value={params['globalKey']} onChange={e => {
                    onParamsChange('globalKey', e.target.value);
                }}></Input>
            <Table<T> style={{ marginTop: 5 }} columns={[...columns.filter(c => representColumns.includes(c.key)), action]} dataSource={data} pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: total,
                onChange: (page, pageSize) => {
                    setCurrentPage(page);
                    setPageSize(pageSize);
                },
            }}
                onChange={
                    (pagination, filters, sorter, extra) => {
                        if (extra.action === 'sort') {
                            setSorterResult(sorter);
                        }
                    }}
                bordered
                loading={loading}
                {...rest}></Table >
        </>
    )
}

export default SearchTable