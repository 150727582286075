import { Button, Col, Divider, Layout, List, Modal, Row, Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ListArrayItem from '../../components/MergeTool/ListArrayItem';
import ListCheckboxItem from '../../components/MergeTool/ListCheckboxItem';
import ListEmptyArrayItem from '../../components/MergeTool/ListEmptyArrayItem';
import ListRadioItem from '../../components/MergeTool/ListRadioItem';
import ListVermittlerItem from '../../components/MergeTool/ListVermittlerItem';
import Title from '../../components/MergeTool/Title';
import Sidebar from '../../components/Sidebar/Sidebar';
import Topbar from '../../components/Topbar/Topbar';
import KundenService from '../../services/KundenService';
import LookUpService from '../../services/LookUpService';
import { Bankverbindung, Bindung, Kontakt, Kunde, KundenAdresse, LookupLists, Mandant, Vermittler, lookup } from '../../services/api-types';
import * as fmt from '../../utils/formatter';
import { errorMessage, handleErrorResponse, hasText, isPresent, noElems } from '../../utils/ts_helpers';
import { AddressType } from '../Customer/Customer';
import usePageHistory from '../../hooks/usePageHistory';

type DataType = Bankverbindung | Kontakt;

const isTextEqual = (value1?: string, value2?: string): boolean => {
    return value1 === value2 || value1?.replace(/\s+/g, ' ').trim() === value2?.replace(/\s+/g, ' ').trim();
};

const isTextEqualIgnoreCase = (value1?: string, value2?: string) => {
    return value1?.replace(/\s+/g, ' ').trim().toLowerCase() === value2?.replace(/\s+/g, ' ').trim().toLowerCase();
};

const isArrayEqual = (array1?: number[], array2?: number[]): boolean => {
    return array1!.length === array2!.length && array1!.sort().every((value, index) => array2!.sort().at(index) === value);
};

const isBankverbindungEqual = (bankverbindung1?: Bankverbindung, bankverbindung2?: Bankverbindung): boolean => {
    if (bankverbindung1 && bankverbindung2) {
        return (bankverbindung1 && bankverbindung2 && (bankverbindung1.iban === bankverbindung2.iban
            || (fmt.blz.print(bankverbindung1.blz) === fmt.blz.print(bankverbindung2.blz) && fmt.konto.print(bankverbindung1.konto) === fmt.konto.print(bankverbindung2.konto))));
    }
    return false;
};

const isKontaktEqual = (kontakt1?: Kontakt, kontakt2?: Kontakt) => {
    if (kontakt1 && kontakt2) {
        return kontakt1 === kontakt2 || (kontakt1 && kontakt2 && kontakt1.kontaktartId === kontakt2.kontaktartId && isTextEqualIgnoreCase(kontakt1.inhalt, kontakt2.inhalt) && isTextEqualIgnoreCase(kontakt1.bezeichnung, kontakt2.bezeichnung));
    }
    return false;
};

const isBindungEqual = (bindung1?: Bindung, bindung2?: Bindung) => {
    if (bindung1 && bindung2) {
        return bindung1 === bindung2 || (bindung1.id.zielKunde === bindung2.id.zielKunde && bindung1.id.bindungsart === bindung2.id.bindungsart);
    }
    return false;
}

const isAddressEqual = (address1?: KundenAdresse, address2?: KundenAdresse) => {
    return address1?.adressart === address2?.adressart
        && isTextEqualIgnoreCase(address1?.strasse, address2?.strasse)
        && isTextEqualIgnoreCase(address1?.hausnr, address2?.hausnr)
        && isTextEqualIgnoreCase(address1?.plz, address2?.plz)
        && isTextEqualIgnoreCase(address1?.ort, address2?.ort)
        && isTextEqualIgnoreCase(address1?.ortsteil, address2?.ortsteil)
        && isTextEqualIgnoreCase(address1?.land, address2?.land);
};

const handleData = <T extends DataType>(isEqual: (t1?: T, t2?: T) => boolean, array1?: Array<T>, array2?: Array<T>): Array<T> => {
    const newData: Array<T> = [];
    array1?.forEach(d1 => {
        let newD = Object.assign({}, d1);
        delete newD.id;
        newData.push(newD);
    });
    array2?.forEach(d2 => {
        if (!newData.find(nd => isEqual(d2, nd))) {
            let newD = Object.assign({}, d2);
            delete newD.id
            newData.push(newD);
        }
    });
    return newData;
}

const initialSuggest = (
    customer1: Kunde | undefined,
    customer2: Kunde | undefined
): Kunde => {

    let newCustomer: Kunde = {
        name: '',
        smartDifferent: true,
        smartInnovation: true,
        adressen: [],
        bankverbindungen: [],
        kontakte: [],
        bindungen: [],
        vermittler: [],
    };

    if (customer1 && customer2) {
        if (isTextEqual(customer1.vorname, customer2.vorname)) {
            newCustomer.vorname = customer1.vorname;
        }

        if (isTextEqual(customer1.name, customer2.name)) {
            newCustomer.name = customer1.name;
        }

        if (isTextEqual(customer1.zusatzbezeichnung, customer2.zusatzbezeichnung)) {
            newCustomer.zusatzbezeichnung = customer1.zusatzbezeichnung;
        }

        if (isTextEqual(customer1.geburtsname, customer2.geburtsname)) {
            newCustomer.geburtsname = customer1.geburtsname;
        }

        if (isTextEqual(customer1.geburtsdatum, customer2.geburtsdatum)) {
            newCustomer.geburtsdatum = customer1.geburtsdatum;
        }

        if (isTextEqual(customer1.geburtsort, customer2.geburtsort)) {
            newCustomer.geburtsort = customer1.geburtsort;
        }

        if (customer1.geschlecht === customer2.geschlecht) {
            newCustomer.geschlecht = customer1.geschlecht;
        }

        if (isTextEqual(customer1.homepage, customer2.homepage)) {
            newCustomer.homepage = customer1.homepage;
        }

        if (isTextEqual(customer1.anrede, customer2.anrede)) {
            newCustomer.anrede = customer1.anrede;
        }

        if (isArrayEqual(customer1.kennzeichnungen || [], customer2.kennzeichnungen || [])) {
            newCustomer.kennzeichnungen = customer1.kennzeichnungen || [];
        }

        if (isArrayEqual(customer1.agenturen || [], customer2.agenturen || [])) {
            newCustomer.agenturen = customer1.agenturen || [];
        }

        if (isArrayEqual(customer1.funktion || [], customer2.funktion || [])) {
            newCustomer.funktion = customer1.funktion || [];
        }

        if (isTextEqual(customer1.ustid, customer2.ustid)) {
            newCustomer.ustid = customer1.ustid;
        }

        if (isTextEqual(customer1.steuernr, customer2.steuernr)) {
            newCustomer.steuernr = customer1.steuernr;
        }

        if (customer1.rechtsform === customer2.rechtsform) {
            newCustomer.rechtsform = customer1.rechtsform;
        }

        if (isArrayEqual(customer1.branche || [], customer2.branche || [])) {
            newCustomer.branche = customer1.branche || [];
        }

        if (isTextEqual(customer1.handelsregister, customer2.handelsregister)) {
            newCustomer.handelsregister = customer1.handelsregister;
        }

        if (isTextEqual(customer1.hrnr, customer2.hrnr)) {
            newCustomer.hrnr = customer1.hrnr;
        }

        if (customer1.vermittler === customer2.vermittler) {
            newCustomer.vermittler = customer1.vermittler;
        }

        if (isAddressEqual(customer1.adressen?.find(a1 => a1.adressart === AddressType.RECHNUNG), customer2.adressen?.find(a2 => a2.adressart === AddressType.RECHNUNG))) {
            let address = Object.assign({}, customer1.adressen?.find(a1 => a1.adressart === AddressType.RECHNUNG));
            delete address.id;
            newCustomer.adressen?.push(address);
        }

        customer1.adressen?.filter(a1 => a1.adressart === AddressType.ABWEICHEND)
            .forEach(a1 => {
                if (customer2.adressen?.find(a2 => isAddressEqual(a1, a2))) {
                    let address = Object.assign({}, a1);
                    delete address.id;
                    newCustomer.adressen?.push(address);
                }
            });

        newCustomer.bankverbindungen = handleData(isBankverbindungEqual, customer1.bankverbindungen, customer2.bankverbindungen);

        newCustomer.kontakte = handleData(isKontaktEqual, customer1.kontakte, customer2.kontakte);

        [...customer1.vermittler!, ...customer2.vermittler!].forEach(v => {
            newCustomer.vermittler?.push({
                id: { mandant: v.id?.mandant },
                vermittler: v.vermittler,
                untervermittler: v.untervermittler,
            });
        });

        [...customer1.bindungen!, ...customer2.bindungen!].forEach(b => {
            newCustomer.bindungen?.push({ id: { zielKunde: b.id.zielKunde, bindungsart: b.id.bindungsart }, details: b.details });
        });

        newCustomer.bank = {}
        if (isTextEqual(customer1.bank?.blz, customer2.bank?.blz)) {
            newCustomer.bank.blz = customer1.bank?.blz;
        }

        if (isTextEqual(customer1.bank?.bicswift, customer2.bank?.bicswift)) {
            newCustomer.bank.bicswift = customer1.bank?.bicswift;
        }

    }

    return newCustomer;
}

function isMergeProposalComplete(merged: Kunde, customer1: Kunde | undefined, customer2: Kunde | undefined) {
    const fieldResults = [
        merged?.name === customer1?.name || merged?.name === customer2?.name,
        merged.vorname === customer1?.vorname || merged.vorname === customer2?.vorname,
        merged.zusatzbezeichnung === customer1?.zusatzbezeichnung || merged.zusatzbezeichnung === customer2?.zusatzbezeichnung,
        merged.anrede === customer1?.anrede || merged.anrede === customer2?.anrede,
        merged.rechtsform === customer1?.rechtsform || merged.rechtsform === customer2?.rechtsform,
        merged.branche?.some(b => customer1?.branche?.indexOf(b) !== -1 || customer2?.branche?.indexOf(b) !== -1) || merged.branche === customer1?.branche || merged.branche === customer2?.branche,
        merged.kennzeichnungen?.some(k => customer1?.kennzeichnungen?.indexOf(k) !== -1 || customer2?.kennzeichnungen?.indexOf(k) !== -1) || merged.kennzeichnungen === customer1?.kennzeichnungen || merged.kennzeichnungen === customer2?.kennzeichnungen,
        merged.agenturen?.some(a => customer1?.agenturen?.indexOf(a) !== -1 || customer2?.agenturen?.indexOf(a) !== -1) || (noElems(merged.agenturen) && noElems(customer1?.agenturen) && noElems(customer2?.agenturen)),
        merged.funktion?.some(f => customer1?.funktion?.indexOf(f) !== -1 || customer2?.funktion?.indexOf(f) !== -1) || (noElems(merged.funktion) && noElems(customer1?.funktion) && noElems(customer2?.funktion)),
        merged.smartDifferent === (customer1?.smartDifferent || customer2?.smartDifferent),
        merged.smartInnovation === (customer1?.smartInnovation || customer2?.smartInnovation),
        merged.geburtsname === customer1?.geburtsname || merged.geburtsname === customer2?.geburtsname,
        merged.geburtsdatum === customer1?.geburtsdatum || merged.geburtsdatum === customer2?.geburtsdatum,
        merged.geburtsort === customer1?.geburtsort || merged.geburtsort === customer2?.geburtsort,
        merged.geschlecht === customer1?.geschlecht || merged.geschlecht === customer2?.geschlecht,
        merged.homepage === customer1?.homepage || merged.homepage === customer2?.homepage,
        merged.ustid === customer1?.ustid || merged.ustid === customer2?.ustid,
        merged.steuernr === customer1?.steuernr || merged.steuernr === customer2?.steuernr,
        merged.handelsregister === customer1?.handelsregister || merged.handelsregister === customer2?.handelsregister,
        merged.hrnr === customer1?.hrnr || merged.hrnr === customer2?.hrnr,
        merged.geschlecht === customer1?.geschlecht || merged.geschlecht === customer2?.geschlecht,
        merged?.bank?.blz === customer1?.bank?.blz || merged?.bank?.blz === customer2?.bank?.blz,
        merged?.bank?.bicswift === customer1?.bank?.bicswift || merged?.bank?.bicswift === customer2?.bank?.bicswift,
    ];

    return !fieldResults.includes(false);
}

function areBankDetailsComplete(bv?: Bankverbindung[]) {
    const incompleteDetails = bv?.filter(bv => !hasText(bv.iban)) ?? [];
    return incompleteDetails.length === 0;
}

const changeBlz = (blz?: string) => (suggest: Kunde) => {
    let newSuggest = Object.assign({}, suggest);
    newSuggest.bank = Object.assign({}, suggest?.bank)
    newSuggest.bank.blz = blz
    return newSuggest;
}

const changeBicswift = (bicswift?: string) => (suggest: Kunde): Kunde => {
    let newSuggest = Object.assign({}, suggest);
    newSuggest.bank = Object.assign({}, suggest?.bank)
    newSuggest.bank.bicswift = bicswift
    return newSuggest;
}

async function fetchUnmergedAssociatedBanks(kunde: Kunde): Promise<Kunde[]> {
    const bankIds = kunde.bankverbindungen?.map(bv => bv.bank).filter(isPresent)
    if (!bankIds) return [];

    const banks = await KundenService.findAllById(bankIds);

    return banks.filter(bank => !(bank.smartDifferent && bank.smartInnovation));
}

const MergeCustomers = () => {
    
    const pageHistory = usePageHistory();

    const navigate = useNavigate();

    const { Content } = Layout;

    const [lookup, setLookup] = useState(LookupLists.DEFAULT());
    const [isLookUpLoading, setIsLookUpLoading] = useState(true);
    const [isCustomerLoading, setIsCustomerLoading] = useState(true);
    const [vermittlerList, setVermittlerList] = useState<Array<lookup.Vermittler>>([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [dirty, setDirty] = useState(true);

    useEffect(() => {
        LookUpService.getLookUp().then(response => {
            setLookup(response);
            setIsLookUpLoading(false);
        }).catch(error => {
            errorMessage(error);
        });
    }, []);

    const formatText = (text?: string): string => text ?? '';

    const capitalizeFirstLetter = (str?: string): string => {
        return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
    }

    const formatVermittler = (v?: Vermittler): string => {
        if (v) {
            const vermittler = vermittlerList.find(lv => lv.id === v?.vermittler);
            const untervermittler = vermittlerList.find(lv => lv.id === v?.untervermittler);
            return [vermittler ? 'Vermittler: ' + vermittler.name : vermittler,
            untervermittler ? 'Untervermittler: ' + untervermittler.name : untervermittler].filter(isPresent).join(', ');
        }
        return '';
    }

    const formatBindung = (b?: Bindung): string => {
        if (b) {
            return [lookup.bindungsartList?.find(ba => b.id.bindungsart === ba.id)?.bindungsart, fmt.kunde(list?.find(k => k.id === b.id.zielKunde))].filter(isPresent).join(': ');
        }
        return '';
    }

    const renderKennzeichnung = (kennzeichnung?: lookup.Kennzeichnung) => {
        if (kennzeichnung) {
            return <div>
                <div style={{
                    height: 13,
                    width: 13,
                    border: '1px solid black',
                    background: kennzeichnung.color,
                    borderRadius: '50%',
                    display: "inline-block",
                    marginBottom: -2,
                }} /> {kennzeichnung.kennzeichnung}
            </div>
        }
        return '';
    };

    const handleSuggestChange = <K extends keyof Kunde>(key: K, value: Kunde[K] | undefined) => {
        setSuggest((suggest) => {
            let newSuggest = Object.assign({}, suggest);
            if (value === undefined) {
                delete newSuggest[key];
            } else {
                newSuggest[key] = value;
            }
            return newSuggest;
        });
    }

    const { id1, id2 } = useParams();

    const [customer1, setCustomer1] = useState<Kunde>();
    const [customer2, setCustomer2] = useState<Kunde>();
    const [list, setList] = useState<Kunde[]>();

    useEffect(() => {
        const getCustomers = async () => {
            try {
                if (id1 !== undefined && id2 !== undefined) {
                    const customers = await KundenService.findAllById([parseInt(id1), parseInt(id2)]);
                    let ids: number[] = [];
                    customers.forEach(c => c.bindungen?.forEach(b => {
                        if (b.id.zielKunde !== undefined && ids.indexOf(b.id.zielKunde) === -1) {
                            ids = [...ids, b.id.zielKunde];
                        }
                    }));

                    setCustomer1(customers.find(c => c.id === parseInt(id1)));
                    setCustomer2(customers.find(c => c.id === parseInt(id2)));

                    const response = await KundenService.findAllById(ids);
                    setList(response);
                    setIsCustomerLoading(false);
                }
            } catch (error) {
                errorMessage(error);
            }
        }
        getCustomers();
    }, [id1, id2]);

    // lists all banks associated with customer1 / 2 that are not yet merged
    const [unmergedBanks1, setUnmmergedBanks1] = useState<Array<Kunde>>([]);
    const [unmergedBanks2, setUnmmergedBanks2] = useState<Array<Kunde>>([]);
    const [warningsAcknowlegded, setWarningsAcknowlegded] = useState(false);

    useEffect(() => {
        async function fetchUnmerged() {
            if (customer1) {
                setUnmmergedBanks1(await fetchUnmergedAssociatedBanks(customer1));
            }
        }

        fetchUnmerged().catch(e => console.error("Failed to fetch unmerged banks 1", e));
    }, [customer1]);

    useEffect(() => {
        async function fetchUnmerged() {
            if (customer2) {
                const umb = await fetchUnmergedAssociatedBanks(customer2);
                setUnmmergedBanks2(umb);
            }
        }

        fetchUnmerged().catch(e => console.error("Failed to fetch unmerged banks 2"));
    }, [customer2]);

    const unmergedBanks = [...unmergedBanks1, ...unmergedBanks2];

    const incompleteBankDetailCustomers: Kunde[] = [
        customer1,
        customer2,
    ]
        .filter(isPresent)
        .filter(c => !areBankDetailsComplete(c?.bankverbindungen));

    const warnings: JSX.Element | false = (unmergedBanks.length + incompleteBankDetailCustomers.length > 0) &&
        <>
            <h3>Zusammenführen nicht möglich</h3>
            {unmergedBanks.length > 0 &&
                <>
                    <p>Folgende Banken aus Bankverbindungen sind noch nicht zusammengeführt:</p>
                    <ul>
                        {unmergedBanks.map(b =>
                            <li>{fmt.kunde(b)}</li>
                        )}
                    </ul>
                </>
            }
            {incompleteBankDetailCustomers.length > 0 &&
                <>
                    <p>Folgende Personen haben Bankverbindungen, in denen die IBAN fehlt:</p>
                    <ul>
                        {incompleteBankDetailCustomers.map(c =>
                            <li><a href={`/#/customer/${c.id}`} target='_blank'>Person {c.id}</a> (<span>{fmt.kunde(c)}</span>)</li>
                        )}
                    </ul>
                </>
            }
        </>

    useEffect(() => {
        const loadVermittler = async () => {
            try {
                const vermittlerList = await LookUpService.getVermittlerListByIds([
                    customer1?.vermittler?.find(v => v.id?.mandant === Mandant.SMART_DIFFERENT)?.vermittler,
                    customer1?.vermittler?.find(v => v.id?.mandant === Mandant.SMART_DIFFERENT)?.untervermittler,
                    customer1?.vermittler?.find(v => v.id?.mandant === Mandant.SMART_INNOVATION)?.vermittler,
                    customer1?.vermittler?.find(v => v.id?.mandant === Mandant.SMART_INNOVATION)?.untervermittler,
                    customer2?.vermittler?.find(v => v.id?.mandant === Mandant.SMART_DIFFERENT)?.vermittler,
                    customer2?.vermittler?.find(v => v.id?.mandant === Mandant.SMART_DIFFERENT)?.untervermittler,
                    customer2?.vermittler?.find(v => v.id?.mandant === Mandant.SMART_INNOVATION)?.vermittler,
                    customer2?.vermittler?.find(v => v.id?.mandant === Mandant.SMART_INNOVATION)?.untervermittler,].filter((value, index, array) => index === array.indexOf(value)));
                setVermittlerList(vermittlerList);
            } catch (error) {
                handleErrorResponse(error);
            }
        }
        loadVermittler();
    }, [customer1, customer2]);

    const [newSuggest, setSuggest] = useState<Kunde>(Kunde.DEFAULT());

    useEffect(() => {
        setSuggest(initialSuggest(customer1, customer2));
    }, [customer1, customer2]);

    useEffect(() => {
        setDirty(!isMergeProposalComplete(newSuggest, customer1, customer2));
    }, [newSuggest, customer1, customer2]);

    return (
        <Layout>
            <Topbar></Topbar>
            <Layout>
                <Sidebar />
                <Content style={{
                    padding: 20,
                    boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)',
                    margin: 20,
                }}>
                    <>
                        {
                            isLookUpLoading || isCustomerLoading ?
                                <Row justify='center'>
                                    <Spin size='large'></Spin>
                                </Row> :
                                !customer1 ? errorMessage('Oops, es ist irgendwie schief gelaufen. Kunde 1 wurde nicht gefunden.') :
                                    !customer2 ? errorMessage('Oops, es ist irgendwie schief gelaufen. Kunde 2 wurde nicht gefunden.') :
                                        !list ? errorMessage('Oops, es ist irgendwie schief gelaufen. Bindungen wurden nicht gefunden.') :
                                            <div>
                                                <Title customer1={customer1} customer2={customer2} title={'Ergebnis'} />
                                                <List grid={{ gutter: 8 }}>
                                                    {
                                                        (Object.keys(customer1) as Array<keyof Kunde>).map(
                                                            key => {
                                                                const itemName = capitalizeFirstLetter(key);
                                                                if (typeof customer1[key] === 'string') {
                                                                    return <ListRadioItem
                                                                        itemName={itemName}
                                                                        value1={customer1[key]?.toString()}
                                                                        value2={customer2[key]?.toString()}
                                                                        result={newSuggest[key]?.toString()}
                                                                        isEqual={isTextEqual}
                                                                        formatLabel={formatText}
                                                                        handleValueChange={(value: string) => handleSuggestChange(key, value)}
                                                                    />
                                                                }

                                                                if (key === 'branche') {
                                                                    return <ListCheckboxItem
                                                                        itemName={itemName}
                                                                        value1={customer1[key]}
                                                                        value2={customer2[key]}
                                                                        result={newSuggest[key]}
                                                                        formatLabel={(branche?: number[]) =>
                                                                            branche ?
                                                                                branche.map(b => lookup.brancheList.find(lb => lb.id === b)?.branche)
                                                                                    .filter(isPresent).join(', ') : ''
                                                                        }
                                                                        isEqual={isArrayEqual}
                                                                        handleValueChange={(value?: number[]) => handleSuggestChange(key, value)}
                                                                    />
                                                                }

                                                                if (key === 'funktion') {
                                                                    return <ListCheckboxItem
                                                                        itemName={itemName}
                                                                        value1={customer1[key]}
                                                                        value2={customer2[key]}
                                                                        result={newSuggest[key]}
                                                                        formatLabel={(funktion?: number[]) =>
                                                                            funktion ?
                                                                                funktion.map(f => lookup.funktionList.find(lf => lf.id === f)?.funktion)
                                                                                    .filter(isPresent).join(', ') : ''
                                                                        }
                                                                        isEqual={isArrayEqual}
                                                                        handleValueChange={(value?: number[]) => handleSuggestChange(key, value)}
                                                                    />
                                                                }

                                                                if (key === 'rechtsform') {
                                                                    return <ListRadioItem
                                                                        itemName={itemName}
                                                                        value1={customer1[key]}
                                                                        value2={customer2[key]}
                                                                        result={newSuggest[key]}
                                                                        formatLabel={(rechtsform?: number) => {
                                                                            const label = lookup.rechtsformList.find(r => r.id === rechtsform);
                                                                            return label ? label.rechtsform : '';
                                                                        }}
                                                                        isEqual={(v1, v2) => v1 === v2}
                                                                        handleValueChange={(value?: number) => handleSuggestChange(key, value)}
                                                                    />
                                                                }

                                                                if (key === 'kennzeichnungen') {
                                                                    return <ListRadioItem
                                                                        itemName={itemName}
                                                                        value1={customer1[key]?.at(0)}
                                                                        value2={customer2[key]?.at(0)}
                                                                        result={newSuggest[key]?.at(0)}
                                                                        formatLabel={(kennzeichnung?: number) => {
                                                                            return renderKennzeichnung(lookup.kennzeichnungList.find(k => k.id === kennzeichnung))
                                                                        }}
                                                                        isEqual={(v1, v2) => v1 === v2}
                                                                        handleValueChange={(value?: number) => value ? handleSuggestChange(key, [value]) : handleSuggestChange(key, [])}
                                                                    />
                                                                }

                                                                if (key === 'agenturen') {
                                                                    return <ListRadioItem
                                                                        itemName={itemName}
                                                                        value1={customer1[key]?.at(0)}
                                                                        value2={customer2[key]?.at(0)}
                                                                        result={newSuggest[key]?.at(0)}
                                                                        formatLabel={(agentur?: number) => {
                                                                            const label = lookup.agenturList.find(a => a.id === agentur);
                                                                            return label ? label.agentur : '';
                                                                        }}
                                                                        isEqual={(v1, v2) => v1 === v2}
                                                                        handleValueChange={(value?: number) => value ? handleSuggestChange(key, [value]) : handleSuggestChange(key, [])}
                                                                    />
                                                                }

                                                                if (key === 'geschlecht') {
                                                                    return <ListRadioItem
                                                                        itemName={itemName}
                                                                        value1={customer1[key]}
                                                                        value2={customer2[key]}
                                                                        result={newSuggest[key]}
                                                                        formatLabel={(geschlecht?: number) => {
                                                                            const label = lookup.geschlechtList.find(g => g.id === geschlecht);
                                                                            return label ? label.geschlecht : '';
                                                                        }}
                                                                        isEqual={(v1, v2) => v1 === v2}
                                                                        handleValueChange={(value?: number) => handleSuggestChange(key, value)}
                                                                    />
                                                                }

                                                                if (key === 'bank') {
                                                                    return <>
                                                                        <ListRadioItem
                                                                            itemName={"BLZ"}
                                                                            value1={customer1[key]?.blz}
                                                                            value2={customer2[key]?.blz}
                                                                            result={newSuggest[key]?.blz}
                                                                            handleValueChange={(value?: string | undefined) => setSuggest(changeBlz(value))}
                                                                            isEqual={(a: string | undefined, b: string | undefined): boolean => a === b}
                                                                            formatLabel={
                                                                                (blz?: string | undefined): JSX.Element => <>{blz}</>
                                                                            }
                                                                        />
                                                                        <ListRadioItem
                                                                            itemName={"BIC"}
                                                                            value1={customer1[key]?.bicswift}
                                                                            value2={customer2[key]?.bicswift}
                                                                            result={newSuggest[key]?.bicswift}
                                                                            handleValueChange={(value?: string | undefined) => setSuggest(changeBicswift(value))}
                                                                            isEqual={(a: string | undefined, b: string | undefined): boolean => a === b}
                                                                            formatLabel={
                                                                                (bicswift?: string | undefined): JSX.Element => <>{bicswift}</>
                                                                            }
                                                                        />
                                                                    </>
                                                                }

                                                                return undefined;
                                                            }
                                                        )
                                                    }

                                                    {/** address list */}
                                                    {
                                                        noElems(customer1.adressen) && noElems(customer2.adressen) ?
                                                            <ListEmptyArrayItem itemName='Adressen' />
                                                            :
                                                            <>
                                                                <Divider orientation='left'>Adressen</Divider>
                                                                <ListRadioItem
                                                                    value1={customer1.adressen?.find(a1 => a1.adressart === AddressType.RECHNUNG)}
                                                                    value2={customer2.adressen?.find(a2 => a2.adressart === AddressType.RECHNUNG)}
                                                                    result={newSuggest.adressen?.find(a => a.adressart === AddressType.RECHNUNG)}
                                                                    isEqual={isAddressEqual}
                                                                    formatLabel={fmt.adresse}
                                                                    handleValueChange={(value: KundenAdresse) => {
                                                                        setSuggest(prev => {
                                                                            let newSuggest = Object.assign({}, prev);
                                                                            let newAddress = Object.assign({}, value);
                                                                            delete newAddress.id;
                                                                            let candidat = newSuggest.adressen?.find(a => a.adressart === AddressType.RECHNUNG);
                                                                            if (candidat) {
                                                                                candidat = newAddress;
                                                                            } else {
                                                                                newSuggest.adressen = [...newSuggest.adressen!, newAddress]
                                                                            }
                                                                            return newSuggest;
                                                                        });
                                                                    }}
                                                                />
                                                                <>
                                                                    {
                                                                        customer1.adressen?.filter(a1 => a1.adressart === AddressType.ABWEICHEND).map(a1 => <ListRadioItem
                                                                            value1={a1}
                                                                            value2={customer2.adressen?.find(a2 => isAddressEqual(a1, a2))}
                                                                            result={newSuggest.adressen?.find(a => isAddressEqual(a, a1))}
                                                                            isEqual={isAddressEqual}
                                                                            formatLabel={fmt.adresse}
                                                                            handleValueChange={(value?: KundenAdresse) => {
                                                                                setSuggest(prev => {
                                                                                    let newSuggest = Object.assign({}, prev);
                                                                                    let oldValue = newSuggest.adressen?.find(a => isAddressEqual(a, a1));
                                                                                    if (!value && oldValue) {
                                                                                        newSuggest.adressen?.splice(newSuggest.adressen?.findIndex(a => isAddressEqual(a, a1)), 1);
                                                                                    }
                                                                                    if (value && !oldValue) {
                                                                                        let newAddress = Object.assign({}, value);
                                                                                        delete newAddress.id;
                                                                                        newSuggest.adressen?.push(newAddress);
                                                                                    }
                                                                                    return newSuggest;
                                                                                });
                                                                            }}></ListRadioItem>)
                                                                    }
                                                                </>
                                                                <>
                                                                    {
                                                                        customer2.adressen?.filter(a2 => a2.adressart === AddressType.ABWEICHEND && customer1.adressen?.find(a1 => !isAddressEqual(a1, a2)))
                                                                            .map(a2 => <ListRadioItem
                                                                                value1={customer1.adressen?.find(a1 => isAddressEqual(a1, a2))}
                                                                                value2={a2}
                                                                                result={newSuggest.adressen?.find(a => isAddressEqual(a, a2))}
                                                                                isEqual={isAddressEqual}
                                                                                formatLabel={fmt.adresse}
                                                                                handleValueChange={(value: KundenAdresse) => {
                                                                                    setSuggest(prev => {
                                                                                        let newSuggest = Object.assign({}, prev);
                                                                                        let oldValue = newSuggest.adressen?.find(a => isAddressEqual(a, a2));
                                                                                        if (!value && oldValue) {
                                                                                            newSuggest.adressen?.splice(newSuggest.adressen?.findIndex(a => isAddressEqual(a, a2)), 1);
                                                                                        }
                                                                                        if (value && !oldValue) {
                                                                                            let newAddress = Object.assign({}, value);
                                                                                            delete newAddress.id;
                                                                                            newSuggest.adressen?.push(newAddress);
                                                                                        }
                                                                                        return newSuggest;
                                                                                    });
                                                                                }}
                                                                            />)
                                                                    }
                                                                </>
                                                            </>
                                                    }

                                                    {/** bank account list */
                                                        noElems(customer1.bankverbindungen) && noElems(customer2.bankverbindungen) ?
                                                            <ListEmptyArrayItem itemName='Bankverbindungen'></ListEmptyArrayItem>
                                                            :
                                                            <ListArrayItem
                                                                itemName='Bankverbindungen'
                                                                array1={customer1.bankverbindungen}
                                                                array2={customer2.bankverbindungen}
                                                                result={newSuggest.bankverbindungen}
                                                                handleValueChange={(ob?: Bankverbindung, nb?: Bankverbindung) => {
                                                                    if (ob && !nb) {
                                                                        setSuggest(prev => {
                                                                            let newSuggest = Object.assign({}, prev);
                                                                            newSuggest.bankverbindungen?.splice(newSuggest.bankverbindungen?.findIndex(b => b === ob), 1);
                                                                            return newSuggest;
                                                                        });
                                                                    }

                                                                    if (!ob && nb) {
                                                                        setSuggest(prev => {
                                                                            let newSuggest = Object.assign({}, prev);
                                                                            let newBankAccount = Object.assign({}, nb);
                                                                            delete newBankAccount.id;
                                                                            newSuggest.bankverbindungen?.push(newBankAccount);
                                                                            return newSuggest;
                                                                        });
                                                                    }
                                                                }}
                                                                isEqual={isBankverbindungEqual}
                                                                format={(v?: Bankverbindung) => v?.iban ?? ''}
                                                            ></ListArrayItem>
                                                    }


                                                    {/** contact list */
                                                        noElems(customer1.kontakte) && noElems(customer2.kontakte) ?
                                                            <ListEmptyArrayItem itemName='Kontakte'></ListEmptyArrayItem>
                                                            :
                                                            <ListArrayItem
                                                                itemName='Kontakte'
                                                                array1={customer1.kontakte}
                                                                array2={customer2.kontakte}
                                                                result={newSuggest.kontakte}
                                                                handleValueChange={(ok?: Kontakt, nk?: Kontakt) => {
                                                                    if (ok && !nk) {
                                                                        setSuggest(prev => {
                                                                            let newSuggest = Object.assign({}, prev);
                                                                            newSuggest.kontakte?.splice(newSuggest.kontakte?.findIndex(k => k === ok), 1);
                                                                            return newSuggest;
                                                                        });
                                                                    }

                                                                    if (!ok && nk) {
                                                                        setSuggest(prev => {
                                                                            let newSuggest = Object.assign({}, prev);
                                                                            let newContact = Object.assign({}, nk);
                                                                            delete newContact.id;
                                                                            newSuggest.kontakte?.push(newContact);
                                                                            return newSuggest;
                                                                        });
                                                                    }
                                                                }}
                                                                isEqual={isKontaktEqual}
                                                                format={(k?: Kontakt) => fmt.kontakt(k, lookup.kontaktartList)} />

                                                    }

                                                    {/** intermediary */
                                                        noElems(customer1.vermittler) && noElems(customer2.vermittler) ?
                                                            <ListEmptyArrayItem itemName='Vermittler'></ListEmptyArrayItem>
                                                            :
                                                            <>
                                                                <Divider orientation='left'>Vermittler</Divider>
                                                                {
                                                                    Object.values(Mandant).map(m => <ListVermittlerItem
                                                                        value1={customer1.vermittler}
                                                                        value2={customer2.vermittler}
                                                                        result={newSuggest.vermittler}
                                                                        itemName={m.split('_').map(capitalizeFirstLetter).join(' ')}
                                                                        formatVermittler={(formatVermittler)}
                                                                        isVermittlerEqual={() => true}
                                                                        setSuggest={setSuggest}
                                                                        mandant={m} />
                                                                    )
                                                                }
                                                            </>
                                                    }


                                                    {/** relationships or bindings */
                                                        noElems(customer1.bindungen) && noElems(customer2.bindungen) ?
                                                            <ListEmptyArrayItem itemName='Bindungen'></ListEmptyArrayItem>
                                                            :
                                                            <>
                                                                <Divider orientation='left'>Bindungen</Divider>
                                                                {
                                                                    customer1.bindungen?.map(b1 => {
                                                                        return <ListRadioItem
                                                                            value1={b1}
                                                                            value2={customer2.bindungen?.find(b2 => isBindungEqual(b1, b2))}
                                                                            result={newSuggest.bindungen?.find(nb => isBindungEqual(nb, b1))}
                                                                            isEqual={() => true}
                                                                            formatLabel={formatBindung}
                                                                            handleValueChange={(b?: Bindung) => {
                                                                                return;
                                                                            }}
                                                                        ></ListRadioItem>
                                                                    })
                                                                }
                                                                {
                                                                    customer2.bindungen?.map(b2 => {
                                                                        return <ListRadioItem
                                                                            value1={customer1.bindungen?.find(b1 => isBindungEqual(b1, b2))}
                                                                            value2={b2}
                                                                            result={newSuggest.bindungen?.find(nb => isBindungEqual(nb, b2))}
                                                                            isEqual={() => true}
                                                                            formatLabel={formatBindung}
                                                                            handleValueChange={(b?: Bindung) => {
                                                                                return;
                                                                            }}
                                                                        ></ListRadioItem>
                                                                    })
                                                                }
                                                            </>
                                                    }
                                                </List>
                                            </div>
                        }
                        <Row>
                            <Col offset={21} style={{ marginBottom: 10 }}><Button disabled={dirty || unmergedBanks.length > 0} danger onClick={() => {
                                setConfirmOpen(true);
                            }}>Zusammenführen</Button></Col>

                            {warnings &&
                                <Modal open={!warningsAcknowlegded}
                                    cancelButtonProps={{ style: { display: 'none' } }}
                                    onOk={() => setWarningsAcknowlegded(true)}>
                                    {warnings}
                                </Modal>
                            }
                        </Row>
                        <Modal destroyOnClose={true} open={confirmOpen}
                            maskClosable={false} okButtonProps={{ danger: true }} title="Bestätigung erforderlich" onOk={() => {
                                if (customer1 && customer2 && customer1.id && customer2.id) {
                                    KundenService.merge({
                                        source1: customer1.id,
                                        source2: customer2.id,
                                        kunde: newSuggest,
                                        addresses: newSuggest.adressen?.filter(isPresent).sort((a, b) => a.adressart! - b.adressart!) ?? [],
                                        bankaccounts: newSuggest.bankverbindungen?.filter(isPresent) ?? [],
                                        contacts: newSuggest.kontakte?.filter(isPresent) ?? [],
                                        vermittler: newSuggest.vermittler?.filter(isPresent) ?? []
                                    }).then(
                                        _response => {
                                            message.success('Kunden wurden erfolgreich zusammengeführt.');
                                            navigate('/merge', { replace: true });
                                        }
                                    ).catch(error => errorMessage(error));
                                } else {
                                    errorMessage('Ooops, es ist irgendwas schief gelaufen. Bitte versuchen Sie noch Mal.');
                                }
                            }} okText={'Bestätigen'} onCancel={() => setConfirmOpen(false)}>
                            <p>{'Diese Datensätze wirklich zusammenführen?'}</p>
                        </Modal>
                    </>
                </Content>
            </Layout>
        </Layout >
    )
}

export default MergeCustomers

export const __tests__ = {
    areBankDetailsComplete,
    isAddressEqual,
    isArrayEqual,
    isBankverbindungEqual,
    isBindungEqual,
    isKontaktEqual,
    isMergeProposalComplete,
    isTextEqual,
    isTextEqualIgnoreCase,
}
