import { Alert, Button, Modal } from "antd";

interface SaveWarningProps {
    message: string;
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}


const SaveWarning = ({
    message, open, onCancel, onConfirm
}: SaveWarningProps) => {
    return (
        <Modal
            open={open}
            maskClosable={false}
            footer={
                <>
                    <Button onClick={onCancel}>
                        Abbrechen
                    </Button>
                    <Button type="primary" onClick={onConfirm}>
                        Bestätigen
                    </Button>
                </>
            }><Alert
                message="Warnung"
                description={message}
                type="warning"
                showIcon
            /></Modal>
    )
}

export default SaveWarning;