import { DeleteOutlined, PlusOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Select, Table, Typography } from 'antd';
import { Bindung, Kunde, LookupLists } from '../../services/api-types';
import * as fmt from '../../utils/formatter';
import { createNameOfGmeinsamerPerson } from '../../utils/ts_helpers';
import { BindungId, FrontendId } from '../../utils/ui-ids';
import { Bindungsarten } from '../../pages/Customer/Customer';
import { useState } from 'react';
import { ColumnsType } from 'antd/es/table';


interface EditBindungenProps {
    lookup: LookupLists
    mainPerson: Kunde
    associatedPerson: Kunde
    bindungen: Record<BindungId.Type, Bindung[]>
    onBindungenChange: (b: Record<BindungId.Type, Bindung[]>) => void
    deletedBindungen: Record<BindungId.Type, Bindung[]>
    onDeletedBindungenChange: (b: Record<BindungId.Type, Bindung[]>) => void
    enabled?: boolean
    isProposal: boolean
    createGemeinsamerPerson: (p1: Kunde, p2: Kunde) => void
}


export function EditBindungen({
    lookup,
    mainPerson,
    associatedPerson,
    bindungen,
    onBindungenChange,
    deletedBindungen,
    onDeletedBindungenChange,
    enabled = true,
    isProposal,
    createGemeinsamerPerson
}: EditBindungenProps) {

    const { Option } = Select;

    const [fwdBindung, setFwdBindung] = useState<Bindung>({ id: { kunde: mainPerson.id, zielKunde: associatedPerson.id } });
    const [bwdBindung, setBwdBindung] = useState<Bindung>({ id: { kunde: associatedPerson.id, zielKunde: mainPerson.id } });

    const hasDetails: (bindungsart?: number) => boolean = bindungsart => bindungsart === Bindungsarten.ARBEITERNEHMER || bindungsart === Bindungsarten.FAMILIENMITGLIED;

    const fwdBindungId = BindungId.fromKunden(mainPerson, associatedPerson);
    const bwdBindungId = BindungId.fromKunden(associatedPerson, mainPerson);

    const fwd_is_valid = !enabled || (bindungen[fwdBindungId] || []).length > 0
    const bwd_is_valid = !enabled || (bindungen[bwdBindungId] || []).length > 0

    const getRueckbindungsart = (bindung: Bindung) => lookup.bindungsartList.find(ba => ba.id === bindung.id.bindungsart)?.rueckbindungsart

    const showSpouseButton = () => {
        const validBindungen = Object.entries(bindungen).filter(b => b[1].length);

        if (!fwd_is_valid || !bwd_is_valid) {
            return false;
        }

        const fwdBindungen = bindungen[fwdBindungId];
        const bwdBindungen = bindungen[bwdBindungId];

        const isGemeinsamerVertragspartner = [...fwdBindungen, ...bwdBindungen].some(b => b.id.bindungsart === Bindungsarten.GEMEINSAMER_VERTRAGSPARTNER);

        if (isGemeinsamerVertragspartner) {
            return false;
        }

        const isEhePaar = [...fwdBindungen, ...bwdBindungen].some(b => b.id.bindungsart === Bindungsarten.EHEPARTNER);

        const gemeinsamerVertragspartnerbindungen = validBindungen.filter((b) => b[1].map(b => b.id.bindungsart).includes(Bindungsarten.GEMEINSAMER_VERTRAGSPARTNER))
        const targets = gemeinsamerVertragspartnerbindungen.map(b => BindungId.getTarget(b[0] as BindungId.Type));
        const sources = gemeinsamerVertragspartnerbindungen.map(b => BindungId.getSource(b[0] as BindungId.Type));

        const existsGemeinsamerVertragspartner = [mainPerson, associatedPerson].map(p => FrontendId.fromKunde(p)).some(id => targets.includes(id) || sources.includes(id))

        return isEhePaar && !existsGemeinsamerVertragspartner;
    }

    const fwdColumns: ColumnsType<Bindung> = [{
        title: 'Bindung',
        dataIndex: 'bindung',
        render: (_value, record, _index) => lookup.bindungsartList.find(ba => ba.id === record.id.bindungsart)?.bindungsart
    },
    {
        title: 'Position/Details',
        dataIndex: 'details',
        render: (value, record, _index) => hasDetails(record.id.bindungsart!) ?
            <Input value={value} onChange={e => {
                const value = e.target.value;
                let newBindungen = { ...bindungen };
                newBindungen[fwdBindungId].find(b => b === record)!.details = value;
                onBindungenChange(newBindungen);
            }}></Input> : value
    },
    {
        title: 'Aktion',
        dataIndex: 'bindung',
        render: (_value, record, _index) => <Button title="löschen" danger icon={<DeleteOutlined />} onClick={e => {
            let newBindungen = { ...bindungen };
            newBindungen[fwdBindungId] = [...newBindungen[fwdBindungId].filter(b => b !== record)];
            let newDeletedBindungen = { ...deletedBindungen };
            if (!newDeletedBindungen[fwdBindungId]) {
                newDeletedBindungen[fwdBindungId] = [];
            }
            if (newDeletedBindungen[fwdBindungId].find(b => b.id === record.id) === undefined) {
                newDeletedBindungen[fwdBindungId] = [...newDeletedBindungen[fwdBindungId], record];
            }
            const rueckbindungsart = getRueckbindungsart(record);
            if (rueckbindungsart !== undefined) {
                newBindungen[bwdBindungId] = [...newBindungen[bwdBindungId].filter(b => b.id.bindungsart !== rueckbindungsart)];
                if (!newDeletedBindungen[bwdBindungId]) {
                    newDeletedBindungen[bwdBindungId] = [];
                }
                if (newDeletedBindungen[bwdBindungId].find(b => b.id.bindungsart === rueckbindungsart) === undefined) {
                    newDeletedBindungen[bwdBindungId] = [...newDeletedBindungen[bwdBindungId], record];
                }
            }
            onBindungenChange(newBindungen);
            onDeletedBindungenChange(newDeletedBindungen);
        }} />
    }];


    const bwdColumns: ColumnsType<Bindung> = [{
        title: 'Bindung',
        dataIndex: 'bindung',
        render: (_value, record, _index) => lookup.bindungsartList.find(ba => ba.id === record.id.bindungsart)?.bindungsart
    },
    {
        title: 'Position/Details',
        dataIndex: 'details',
        render: (value, record, _index) => hasDetails(record.id.bindungsart!) ?
            <Input value={value} onChange={e => {
                const value = e.target.value;
                let newBindungen = { ...bindungen };
                newBindungen[BindungId.fromKunden(associatedPerson, mainPerson)].find(b => b === record)!.details = value;
                onBindungenChange(newBindungen);
            }}></Input> : value
    },
    {
        title: 'Aktion',
        dataIndex: 'bindung',
        render: (_value, record, _index) => <Button title="löschen" danger icon={<DeleteOutlined />} onClick={e => {
            let newBindungen = { ...bindungen };
            newBindungen[bwdBindungId] = [...newBindungen[bwdBindungId].filter(b => b !== record)];
            let newDeletedBindungen = { ...deletedBindungen };
            if (!newDeletedBindungen[bwdBindungId]) {
                newDeletedBindungen[bwdBindungId] = [];
            }
            if (newDeletedBindungen[bwdBindungId].find(b => b.id === record.id) === undefined) {
                newDeletedBindungen[bwdBindungId] = [...newDeletedBindungen[bwdBindungId], record];
            }
            const rueckbindungsart = getRueckbindungsart(record);
            if (rueckbindungsart !== undefined) {
                newBindungen[fwdBindungId] = [...newBindungen[fwdBindungId].filter(b => b.id.bindungsart !== rueckbindungsart)];
                if (!newDeletedBindungen[fwdBindungId]) {
                    newDeletedBindungen[fwdBindungId] = [];
                }
                if (newDeletedBindungen[fwdBindungId].find(b => b.id.bindungsart === rueckbindungsart) === undefined) {
                    newDeletedBindungen[fwdBindungId] = [...newDeletedBindungen[fwdBindungId], record];
                }
            }
            onBindungenChange(newBindungen);
            onDeletedBindungenChange(newDeletedBindungen);
        }} />
    }];

    return <>
        <Row gutter={[16, 8]}>
            <Col span={12}>
                <Form.Item
                    required={enabled}
                    label={''
                        + fmt.kunde(associatedPerson)
                        + ' ist ... von '
                        + fmt.kunde(mainPerson)
                    }>
                    <Row gutter={[8, 8]}>
                        <Col span={hasDetails(fwdBindung.id.bindungsart) ? 11 : 22}>
                            <Form.Item label={'Neue Bindung'}>
                                <Select value={fwdBindung.id.bindungsart} onChange={value => {
                                    setFwdBindung(prev => {
                                        let newBindung = { ...prev };
                                        if (!hasDetails(newBindung.id.bindungsart)) {
                                            delete newBindung.details;
                                        }
                                        newBindung.id.bindungsart = value;
                                        return newBindung;
                                    });
                                    const rueckbindungsart = getRueckbindungsart(fwdBindung);
                                    if (rueckbindungsart !== undefined) {
                                        setBwdBindung(prev => {
                                            let newBindung = { ...prev };
                                            if (!hasDetails(newBindung.id.bindungsart)) {
                                                delete newBindung.details;
                                            }
                                            newBindung.id.bindungsart = rueckbindungsart;
                                            return newBindung;
                                        });
                                    } else {
                                        if (getRueckbindungsart(bwdBindung) !== undefined) {
                                            setBwdBindung(prev => {
                                                let newBindung = { ...prev };
                                                if (!hasDetails(newBindung.id.bindungsart)) {
                                                    delete newBindung.details;
                                                }
                                                newBindung.id.bindungsart = undefined;
                                                return newBindung;
                                            })
                                        }
                                    }
                                }}>
                                    {lookup.bindungsartList.map(b => <Option value={b.id}>{b.bindungsart}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11} hidden={!hasDetails(fwdBindung.id.bindungsart)}>
                            <Form.Item label={'Position/Details'}>
                                <Input value={fwdBindung.details} onChange={e => {
                                    setFwdBindung(prev => {
                                        let newBindung = { ...prev };
                                        newBindung.details = e.target.value;
                                        return newBindung;
                                    });
                                }}></Input>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item label={' '}>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Table columns={fwdColumns} dataSource={fwd_is_valid ? bindungen[fwdBindungId] : []} pagination={false}></Table>
                    {!fwd_is_valid ? <Typography.Text type="danger">Bitte mindestens eine Bindungsart auswählen!</Typography.Text> : undefined}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    required={enabled} label={''
                        + fmt.kunde(mainPerson)
                        + ' ist ... von '
                        + fmt.kunde(associatedPerson)
                    }>
                    <Row gutter={[8, 8]}>
                        <Col span={hasDetails(bwdBindung.id.bindungsart) ? 11 : 22}>
                            <Form.Item label={'Neue Bindung'}>
                                <Select value={bwdBindung.id.bindungsart} onChange={value => {
                                    setBwdBindung(prev => {
                                        let newBindung = { ...prev };
                                        if (!hasDetails(newBindung.id.bindungsart)) {
                                            delete newBindung.details;
                                        }
                                        newBindung.id.bindungsart = value;
                                        return newBindung;
                                    });

                                    const rueckbindungsart = getRueckbindungsart(bwdBindung);
                                    if (rueckbindungsart !== undefined) {
                                        setFwdBindung(prev => {
                                            let newBindung = { ...prev };
                                            if (!hasDetails(newBindung.id.bindungsart)) {
                                                delete newBindung.details;
                                            }
                                            newBindung.id.bindungsart = rueckbindungsart;
                                            return newBindung;
                                        });
                                    } else {
                                        if (getRueckbindungsart(bwdBindung) !== undefined) {
                                            setFwdBindung(prev => {
                                                let newBindung = { ...prev };
                                                if (!hasDetails(newBindung.id.bindungsart)) {
                                                    delete newBindung.details;
                                                }
                                                newBindung.id.bindungsart = undefined;
                                                return newBindung;
                                            })
                                        }
                                    }
                                }}>
                                    {lookup.bindungsartList.map(b => <Option value={b.id}>{b.bindungsart}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11} hidden={!hasDetails(bwdBindung.id.bindungsart)}>
                            <Form.Item label={'Position/Details'}>
                                <Input value={bwdBindung.details} onChange={e => {
                                    setBwdBindung(prev => {
                                        let newBindung = { ...prev };
                                        newBindung.details = e.target.value;
                                        return newBindung;
                                    });
                                }}></Input>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item label={' '}>
                                <Button icon={<PlusOutlined />} type='primary' ghost onClick={e => {
                                    let newBindungen = { ...bindungen };
                                    if (newBindungen[fwdBindungId] === undefined) {
                                        newBindungen[fwdBindungId] = [];
                                    }
                                    if (fwdBindung.id.bindungsart) {
                                        newBindungen[fwdBindungId] = [...newBindungen[fwdBindungId].filter(b => b.id.bindungsart !== fwdBindung.id.bindungsart), fwdBindung];
                                    }
                                    if (newBindungen[bwdBindungId] === undefined) {
                                        newBindungen[bwdBindungId] = [];
                                    }
                                    if (bwdBindung.id.bindungsart) {
                                        newBindungen[bwdBindungId] = [...newBindungen[bwdBindungId].filter(b => b.id.bindungsart !== bwdBindung.id.bindungsart), bwdBindung];
                                    }
                                    onBindungenChange(newBindungen);
                                    setFwdBindung({ id: { kunde: mainPerson.id, zielKunde: associatedPerson.id } });
                                    setBwdBindung({ id: { kunde: associatedPerson.id, zielKunde: mainPerson.id } });
                                }}></Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Table columns={bwdColumns} dataSource={bwd_is_valid ? bindungen[bwdBindungId] : []} pagination={false}></Table>
                    {!bwd_is_valid ? <Typography.Text type="danger">Bitte mindestens eine Bindungsart auswählen!</Typography.Text> : undefined}
                </Form.Item>
            </Col>
        </Row>
        <Row hidden={isProposal || !showSpouseButton()}>
            <Col>
                Gemeinsame Person Anlegen: <Button title="Anlegen"
                    onClick={() => {
                        Modal.confirm({
                            title: "Anlegen bestätigen?",
                            cancelText: "Abbrechen",
                            okText: "Anlegen",
                            onOk: () => {
                                createGemeinsamerPerson(mainPerson, associatedPerson);
                            },
                            content: `Möchten Sie die gemeinsame Person '${createNameOfGmeinsamerPerson(mainPerson, associatedPerson)}' anlegen?`,
                            closable: true,
                        })
                    }}
                    style={{ color: 'steelblue' }}
                    icon={<UsergroupAddOutlined />}></Button>
            </Col>
        </Row>
    </>

}
