import { AutoComplete, Checkbox, Col, Divider, Form, Input, message, Modal, Radio, Row, Select, Spin, Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import React, { ChangeEvent, ReactNode, useState } from "react";
import Draggable from "react-draggable";
import { Bindungsarten, EditMode, isNaturalPerson, noNameWarningMessage, RechtsformID, sanitizeAdditionalLabel } from "../../pages/Customer/Customer";
import { Bindung, Kunde, LookupLists } from "../../services/api-types";
import KundenService from "../../services/KundenService";
import * as fmt from "../../utils/formatter";
import { createNameOfGmeinsamerPerson, hasElems, hasText, noText } from '../../utils/ts_helpers';
import { BindungId, FrontendId } from '../../utils/ui-ids';
import { EditBindungen } from "./EditBindungen";

// TODO this function could be moved closer to the definition of
const updateAssociatedPersonField = <K extends keyof Kunde>(
    prop: K,
    value: Kunde[K] | null,
    associatedPerson: Kunde): Kunde => {

    let newPerson = { ...associatedPerson };
    if (value === null) {
        delete newPerson[prop];
    } else {
        newPerson[prop] = value;
    }
    return newPerson;
};


interface EditAssociatedPersonsProps {
    visible: boolean
    onCancel: () => void
    onSubmit: () => void
    mode: EditMode
    lookup: LookupLists
    setLookup: (lookup: LookupLists) => void
    bindungen: Record<BindungId.Type, Bindung[]>
    onBindungenChange: (bindungen:Record<BindungId.Type, Bindung[]>) => void
    deletedBindungen: Record<BindungId.Type, Bindung[]>
    onDeletedBindungenChange: (bindungen: Record<BindungId.Type, Bindung[]>) => void
    currentPerson: Kunde
    associatedPerson: Kunde
    setAssociatedPerson: (associatedPerson: Kunde) => void
    proposedAssociatedPersons: Record<FrontendId.Type, Kunde> 
    setProposedAssociatedPersons: (associatedPersons: Record<FrontendId.Type, Kunde>) => void
}


export const EditAssociatedPersons = ({
    visible,
    onSubmit,
    onCancel,
    mode,
    lookup,
    setLookup,
    bindungen,
    onBindungenChange,
    deletedBindungen,
    onDeletedBindungenChange,
    currentPerson,
    associatedPerson,
    setAssociatedPerson,
    proposedAssociatedPersons,
    setProposedAssociatedPersons,
}: EditAssociatedPersonsProps) => {

    const isEdit = mode === EditMode.EDIT_EXISTING;
    const showProposals = mode === EditMode.EDIT_NEW;
    const [controller, setController] = useState<AbortController>();
    const [isCustomerSearching, setIsCustomerSearching] = useState(false);

    const renderOptionForSuggestedCustomers = (customer: Kunde) => ({
        value: customer.id?.toString(),
        label: (
            <Row>
                <Col span={6}>{fmt.kunde(customer)}</Col>
                <Col span={6}>{customer.zusatzbezeichnung}</Col>
                <Col span={4}>{lookup.rechtsformList.find((r) => r.id === customer.rechtsform)?.rechtsform}</Col>
                <Col span={6}>{fmt.adresse(customer.adressen?.[0])}</Col>
                <Col span={1}>{fmt.mandant(customer)}</Col>
            </Row>
        ),
    });

    const createGemeinsamerPerson = (mainPerson: Kunde, associatedPerson: Kunde) => {
        const name = createNameOfGmeinsamerPerson(mainPerson, associatedPerson);
        let newGemeinsamerPerson: Kunde = {
            name: name,
            rechtsform: RechtsformID.EHE,
            smartDifferent: mainPerson.smartDifferent && associatedPerson.smartDifferent,
            smartInnovation: mainPerson.smartInnovation && associatedPerson.smartInnovation,
        }

        let newBindungen: { [bindungId: BindungId.Type]: Bindung[] } =
        {
            [BindungId.fromKunden(newGemeinsamerPerson, mainPerson)]: [{ id: { bindungsart: Bindungsarten.GEMEINSAMER_VERTRAGSPARTNER } }],
            [BindungId.fromKunden(newGemeinsamerPerson, associatedPerson)]: [{ id: { bindungsart: Bindungsarten.GEMEINSAMER_VERTRAGSPARTNER } }],
            [BindungId.fromKunden(mainPerson, newGemeinsamerPerson)]: [{ id: { bindungsart: Bindungsarten.VERTRAGSTEILNEHMER } }],
            [BindungId.fromKunden(associatedPerson, newGemeinsamerPerson)]: [{ id: { bindungsart: Bindungsarten.VERTRAGSTEILNEHMER } }],
        }
        Object.assign(newBindungen, currentBindungen)

        setCurrentBindungen(newBindungen);

        let newProposedAssociatedPerson = Object.assign({}, { [FrontendId.fromKunde(newGemeinsamerPerson)]: newGemeinsamerPerson })
        let newAssociatedPersons = Object.assign({}, proposedAssociatedPersons, newProposedAssociatedPerson)
        setProposedAssociatedPersons(newAssociatedPersons);
        onBindungenChange({ ...bindungen, ...newBindungen });
    }

    const [openAutoCompleteDropdown, setOpenAutoCompleteDropdown] = useState(false);

    const suggestedCustomersOptions = (customers: Kunde[]) =>
        [{
            title: '',
            label: (isCustomerSearching ? <Row justify='center'><Spin size='large'></Spin></Row> :
                <Row>
                    <Col span={6}>Name, Vorname</Col>
                    <Col span={6}>Zusatzbezeichnung</Col>
                    <Col span={4}>Rechtsform</Col>
                    <Col span={6}>Adresse</Col>
                    <Col span={1}>Mandant</Col>
                </Row>
            ),
            options:
                isCustomerSearching ? [] :
                    customers
                        .filter(customer => FrontendId.fromKunde(customer) !== FrontendId.fromKunde(currentPerson))
                        .map(customer => renderOptionForSuggestedCustomers(customer))
        }];

    const [allowMissingBindung, setAllowMissingBindung] = useState<Record<FrontendId.Type, boolean>>({})

    const [lookupCustomers, setLookupCustomers] = useState<Kunde[]>([]);

    const [bindungTargetSearch, setBindungTargetSearch] = useState('');


    // map from bindungsIds to list of bindungsArtIds
    const [currentBindungen, setCurrentBindungen] = useState<{ [bindungsId: BindungId.Type]: Bindung[] }>(bindungen);

    const getLookUpCustomers = async (key: string) => {
        try {
            if (controller) { controller.abort(); }
            setLookupCustomers([])
            const newController = new AbortController();
            setController(newController);
            const rechtsform = lookup.rechtsformList.filter(r => r.rechtsform.toLowerCase().includes(key.toLowerCase())).map(r => r.id);
            const result = await KundenService.getAllKundenByKeyAndRechtsform(key, newController.signal, rechtsform);
            setLookupCustomers(result);
            if (result.length === 0) {
                setOpenAutoCompleteDropdown(false);
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.warn('Abfrage ist gecancelt worden.');
            }
            else {
                message.error(`Oooops, Es ist irgendwie schief gelaufen. Error: ${error}`);
            }
        } finally {
            setIsCustomerSearching(false);
        }
    }

    const updateAllowMissingBindung = (associatedPersonId: string, allow: boolean) => setAllowMissingBindung({
        ...allowMissingBindung,
        ...{ [associatedPersonId]: allow }
    })

    const handleAssociatedPersonSubmit = () => {
        if (associatedPerson.name === '') {
            message.warning(noNameWarningMessage);
        } else if (!(associatedPerson.smartDifferent || associatedPerson.smartInnovation)) {
            message.warning('Wessen Kunde ist "' + associatedPerson.name + '"?');
        } else if (!isCurrentBindungenValid()) {
            message.warning('Bitte mindestens eine Bindungsart auswählen!');
        } else {
            let newAssociatedPerson = Object.assign({}, { [FrontendId.fromKunde(associatedPerson)]: associatedPerson })
            let newAssociatedPersons = Object.assign({}, proposedAssociatedPersons, newAssociatedPerson)
            setProposedAssociatedPersons(newAssociatedPersons);
            onBindungenChange({ ...bindungen, ...currentBindungen });
            setCurrentBindungen({});
            setAssociatedPerson(Kunde.DEFAULT());
            onSubmit();
            setAllowMissingBindung({});
        }
    };


    const handleAssociatedPersonChange = <K extends keyof Kunde>(prop: K, value: Kunde[K] | null) => {
        setAssociatedPerson(updateAssociatedPersonField(prop, value, associatedPerson))
    };

    const onAssociatedAdditionalLabelChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        let input = sanitizeAdditionalLabel(e);
        handleAssociatedPersonChange('zusatzbezeichnung', input);
    };

    const isCurrentBindungenValid = () => {

        const mainBindungen = [
            BindungId.fromKunden(associatedPerson, currentPerson),
            BindungId.fromKunden(currentPerson, associatedPerson)
        ];


        const proposedAllowedMissing = (Object.keys(allowMissingBindung) as FrontendId.Type[]).filter(k => allowMissingBindung[k]);

        const requiredBindungenIds = [
            ...mainBindungen,
            ...(Object.keys(proposedAssociatedPersons) as FrontendId.Type[])
                .filter(key =>
                    key !== FrontendId.fromKunde(associatedPerson)
                    && showProposals
                    && !proposedAllowedMissing.includes(key)
                )
                .flatMap(key => [
                    BindungId.fromKunden(proposedAssociatedPersons[key], associatedPerson),
                    BindungId.fromKunden(associatedPerson, proposedAssociatedPersons[key])
                ]),
        ].sort();

        return requiredBindungenIds.every(id => hasElems(currentBindungen[id]));
    }


    //If running in React Strict mode, ReactDOM.findDOMNode() is deprecated.
    const nodeRef = React.useRef(null);

    return (
        <Modal destroyOnClose={true} width={1024} title={isEdit ? "Bindung bearbeiten für " + fmt.kunde(currentPerson) : "Neue Bindung für " + fmt.kunde(currentPerson)}
            open={visible}
            maskClosable={false}
            onCancel={onCancel}
            onOk={handleAssociatedPersonSubmit}
            okText={isEdit ? 'Aktualisieren' : 'Hinzufügen'}
            modalRender={modal => (
                <Draggable nodeRef={nodeRef} handle=".ant-modal-header">
                    <div ref={nodeRef}>{modal}</div>
                </Draggable>
            )}>
            <Form layout='vertical'>
                <Row style={{ marginTop: 5 }}>
                    <Col span={24}><Form.Item label="Name / Firmenname" required>
                        <AutoComplete
                            popupMatchSelectWidth={1700}
                            dropdownAlign={{ offset: ['-35%'] }}
                            options={suggestedCustomersOptions(lookupCustomers)}
                            onSearch={(value) => {
                                if (hasText(value)) {
                                    setOpenAutoCompleteDropdown(true);
                                    setIsCustomerSearching(true);
                                    getLookUpCustomers(value);
                                } else {
                                    if (controller) {
                                        controller.abort();
                                        setIsCustomerSearching(false);
                                        setOpenAutoCompleteDropdown(false);
                                    }
                                }
                            }}
                            open={openAutoCompleteDropdown}
                            value={isEdit ? associatedPerson.name : bindungTargetSearch}
                            onChange={(value) => {
                                if (value) { setOpenAutoCompleteDropdown(true) } else { setOpenAutoCompleteDropdown(false) }
                                handleAssociatedPersonChange('name', value)
                                setBindungTargetSearch(value)
                            }}
                            onSelect={(value: string, option: { label: ReactNode, value?: string }) => {
                                const selectedKunde = lookupCustomers.find(k => k.id?.toString() === option.value);
                                if (selectedKunde) {
                                    setAssociatedPerson(selectedKunde);
                                    setBindungTargetSearch(selectedKunde.name);
                                    setOpenAutoCompleteDropdown(false);
                                }
                            }}
                            onInputKeyDown={e => {
                                if (e.code === 'Escape') {
                                    e.preventDefault();
                                    setOpenAutoCompleteDropdown(false);
                                }
                                if (e.code === 'Enter') {
                                    e.preventDefault();
                                    setOpenAutoCompleteDropdown(false);
                                }
                            }}
                            onBlur={() => { setOpenAutoCompleteDropdown(false); }}
                        >
                        </AutoComplete>

                    </Form.Item></Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Zusatzbezeichnung">
                            <TextArea value={associatedPerson.zusatzbezeichnung} autoSize={{ minRows: 5, maxRows: 5 }} onChange={onAssociatedAdditionalLabelChange} placeholder="max rows 5"></TextArea>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Rechtsform">
                            <Select
                                showSearch
                                value={associatedPerson.rechtsform}
                                onChange={value => {
                                    handleAssociatedPersonChange('rechtsform', value);
                                }}
                                filterOption={(input, option) => option !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {lookup.rechtsformList.map(r => <Select.Option key={r.id} value={r.id}>{r.rechtsform}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item hidden={!isNaturalPerson(associatedPerson)} label="Vorname">
                            <Input value={associatedPerson.vorname} onChange={e => handleAssociatedPersonChange('vorname', e.target.value)}></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item hidden={!isNaturalPerson(associatedPerson)} label="Geschlecht">
                            <Radio.Group
                                value={associatedPerson.geschlecht}
                                onChange={e => {
                                    handleAssociatedPersonChange('geschlecht', e.target.value);
                                }}>
                                {
                                    lookup.geschlechtList.map(g => <Radio key={g.id} value={g.id}>{g.geschlecht}</Radio>)
                                }
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Relevant für / Kunde von" wrapperCol={{ span: 22 }} required>
                            <Checkbox
                                type='checkbox'
                                onChange={(e) => {
                                    handleAssociatedPersonChange('smartDifferent', e.target.checked)
                                }}
                                checked={associatedPerson.smartDifferent}
                            >Energie
                            </Checkbox>
                            <Checkbox
                                type='checkbox'
                                onChange={(e) => {
                                    handleAssociatedPersonChange('smartInnovation', e.target.checked)
                                }}
                                checked={associatedPerson.smartInnovation}
                            >Leasing</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Branche">
                            <Select
                                mode='multiple'
                                value={associatedPerson.branche}
                                onChange={(value) => {
                                    handleAssociatedPersonChange('branche', value);
                                }}
                                filterOption={(input, option) => option !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {lookup.brancheList.map(b => <Select.Option key={b.id} value={b.id}>{b.branche}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} hidden={noText(associatedPerson.name)}>
                        <EditBindungen
                            lookup={lookup}
                            associatedPerson={associatedPerson}
                            bindungen={currentBindungen}
                            onBindungenChange={(a) => {
                                setCurrentBindungen(a)
                            }}
                            deletedBindungen={deletedBindungen}
                            onDeletedBindungenChange={onDeletedBindungenChange}
                            mainPerson={currentPerson}
                            isProposal={false}
                            createGemeinsamerPerson={createGemeinsamerPerson}
                        />
                        {
                            showProposals &&
                            (Object.keys(proposedAssociatedPersons) as FrontendId.Type[])?.filter(key => key !== FrontendId.fromKunde(associatedPerson)).map(
                                key => <div key={key}>
                                    <Divider plain>
                                        {`Bindungen zu ${fmt.kunde(proposedAssociatedPersons[key])} vorhanden: `}
                                        <Switch checked={!allowMissingBindung[key]} onChange={(checked, ev) => {
                                            updateAllowMissingBindung(key, !checked);
                                        }} /></Divider>
                                    <EditBindungen
                                        associatedPerson={proposedAssociatedPersons[key]}
                                        bindungen={currentBindungen}
                                        onBindungenChange={setCurrentBindungen}
                                        deletedBindungen={deletedBindungen}
                                        onDeletedBindungenChange={onDeletedBindungenChange}
                                        mainPerson={associatedPerson}
                                        lookup={lookup}
                                        enabled={!allowMissingBindung[key]}
                                        isProposal={true}
                                        createGemeinsamerPerson={() => { }}
                                    />
                                </div>
                            )
                        }
                    </Col>
                </Row>
            </Form>
        </Modal>
    );

}
