import { lookup, LookupLists } from './api-types';
import STIClient from './STIClient';

const LOOKUP_REST_BASE_URL = '/lookup';

class LookUpService {
    async getLookUp(): Promise<LookupLists> {
        const resp = await STIClient.get(LOOKUP_REST_BASE_URL, {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
        });

        resp.data.bindungsartList.sort((a: lookup.Bindungsart, b: lookup.Bindungsart) => a.bindungsart.localeCompare(b.bindungsart))
        return resp.data;
    }

    async getVermittlerListByName(name: string, signal: AbortSignal): Promise<Array<lookup.Vermittler>> {
        const response = await STIClient.get(LOOKUP_REST_BASE_URL + `/vermittler/${name}`, {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
            signal: signal
        });
        return response.data;
    }

    async getVermittlerListByIds(ids: Array<number | undefined>): Promise<Array<lookup.Vermittler>> {
        const response = await STIClient.get(LOOKUP_REST_BASE_URL + '/vermittler?ids=' + ids.filter(id => id !== undefined && id !== 0).join(','), {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    }
}

export type { LookupLists };

const lookUpService = new LookUpService();

export default lookUpService;