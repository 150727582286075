import { KundenAdresse } from './api-types';
import STIClient from "./STIClient";

const CUSTOMER_ADDRESS_REST_BASE_URL = '/kundenadresse';

class CustomerAddressService {
    async getAllCustomerAddresses(): Promise<KundenAdresse[]> {
        const res = await STIClient.get(CUSTOMER_ADDRESS_REST_BASE_URL, {
            method: 'GET',
        });
        return res.data;
    }

    async createNewCustomerAddress(customerAddress: KundenAdresse): Promise<KundenAdresse> {
        const res = await STIClient.post(CUSTOMER_ADDRESS_REST_BASE_URL + '/new', customerAddress);
        return res.data;
    }

    async postCustomerAddressList(customerList: Array<KundenAdresse>): Promise<Array<KundenAdresse>> {
        const res = await STIClient.post(CUSTOMER_ADDRESS_REST_BASE_URL + '/list', customerList);
        return res.data;
    }

    async getCustomerAddress(id: number): Promise<KundenAdresse> {
        const res = await STIClient.get(CUSTOMER_ADDRESS_REST_BASE_URL + '/' + id);
        return res.data;
    }

    async updateCustomerAddress(id: number, customerAddress: KundenAdresse): Promise<KundenAdresse> {
        const res = await STIClient.put(CUSTOMER_ADDRESS_REST_BASE_URL + '/' + id, customerAddress);
        return res.data;
    }

    async deleteCustomerAddress(id: number) {
        const res = await STIClient.delete(CUSTOMER_ADDRESS_REST_BASE_URL + '/' + id);
        return res.data;
    }

    async getAllAddresesByStreet(street: string): Promise<string[]> {
        const res = await STIClient.get(CUSTOMER_ADDRESS_REST_BASE_URL + '/strasse/' + street);
        return res.data;
    }

    async getAllLocationsByLocation(location: string): Promise<string[]> {
        const res = await STIClient.get(CUSTOMER_ADDRESS_REST_BASE_URL + '/ort/' + location);
        return res.data;
    }

    async getAllDistrictsByLocation(location: string): Promise<string[]> {
        const res = await STIClient.get(CUSTOMER_ADDRESS_REST_BASE_URL + '/ortsteil/' + location);
        return res.data;
    }

}

const customerAddressService = new CustomerAddressService();

export default customerAddressService;
