import { CarTwoTone, FireTwoTone } from "@ant-design/icons"
import { Kunde } from "../../services/api-types"
import { intersperse } from '../../utils/ts_helpers'
import { ReactElement } from 'react'

interface MandantIconProps {
    customer: Kunde
}

export const MandantIcon = ({customer}: MandantIconProps) =>
    intersperse([
        customer.smartDifferent && <FireTwoTone title='Energie' twoToneColor='red' />,
        customer.smartInnovation && <CarTwoTone title='Leasing' />
    ].filter(a => a), <> </>);
