import { FileDoneOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import KundenService from "../../services/KundenService";
import ZaehlerstanderfassungService from "../../services/ZaehlerstanderfassungService";
import { Kunde, VertragsKost } from "../../services/api-types";
import * as fmt from "../../utils/formatter";
import { hasText } from "../../utils/ts_helpers";
import SearchAutoComplete from "../Form/SearchAutoComplete";
import SearchInput from "../Form/SearchInput";

interface EditVertragInfoProps {
    open: boolean;
    openModal: () => void;
    saveModal: () => void;
    closeModal: () => void;
    currentVertragKost: VertragsKost;
    setCurrentVertragKost: Dispatch<SetStateAction<VertragsKost>>;
    gas: boolean;
}


function EditVertragInfo(props: EditVertragInfoProps) {

    const { Option } = Select;

    const [readyToSave, setReadyToSave] = useState(false);
    useEffect(() => {

        const cv = props.currentVertragKost;

        const isStromReady = !props.gas
            && cv.preisstand !== undefined
            && (cv.arbeitspreis !== undefined || (cv.htap !== undefined && cv.ntap !== undefined))
            && cv.grundpreis !== undefined
            && cv.quelle !== undefined
            && hasText(cv.tarifname)
            && cv.versorger !== undefined;

        const isGasReady = props.gas
            && cv.preisstand !== undefined
            && cv.arbeitspreis !== undefined
            && (cv.htap === undefined || cv.htap === 0) 
            && (cv.ntap === undefined || cv.ntap === 0)
            && cv.grundpreis !== undefined
            && cv.quelle !== undefined
            && hasText(cv.tarifname)
            && cv.versorger !== undefined;

        setReadyToSave(isStromReady || isGasReady);
    }, [props.currentVertragKost]);

    const handleVertragKostChange = <K extends keyof VertragsKost>(prop: K, value: VertragsKost[K] | null) => {
        props.setCurrentVertragKost(prev => {
            let v = Object.assign({}, prev);
            if (value === null) {
                delete v[prop];
            } else {
                v[prop] = value;
            }
            return v;
        });
    };

    return <>
        Vergleichsvertrag efassen: <Button ghost type="primary" onClick={props.openModal}><FileDoneOutlined></FileDoneOutlined></Button>
        <Modal
            maskClosable={false}
            width={1000}
            title={props.gas ? "Gasvertrag zum Vergleich erfassen" : "Stromvertrag zum Vergleich erfassen"}
            open={props.open}
            onCancel={props.closeModal}
            footer={[
                <Button key="submit" type="primary" disabled={!readyToSave} onClick={props.saveModal}>
                    Speichern
                </Button>,
            ]}>
            <Form layout="vertical">
                <Row gutter={[8, 8]}>
                    <Col span={props.gas ? 12 : 6}>
                        <Form.Item required label="Grundpreis netto in €/a" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                controls={false} style={{ width: '100%' }} value={props.currentVertragKost.grundpreis} onChange={value => handleVertragKostChange('grundpreis', value)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={props.gas ? 12 : 6}>
                        <Form.Item required label={props.gas ? "Gas Arbeitspreis netto in ct/kWh" : "ET Arbeitspreis netto in ct/kWh"} wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                controls={false} style={{ width: '100%' }} value={props.currentVertragKost.arbeitspreis} onChange={value => handleVertragKostChange('arbeitspreis', value)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={6} hidden={props.gas}>
                        <Form.Item required label="HT Arbeitspreis netto in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                controls={false} style={{ width: '100%' }} value={props.currentVertragKost.htap} onChange={value => handleVertragKostChange('htap', value)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={6} hidden={props.gas}>
                        <Form.Item required label="NT Arbeitspreis netto in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                controls={false} style={{ width: '100%' }} value={props.currentVertragKost.ntap} onChange={value => handleVertragKostChange('ntap', value)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item required label="Versorger" wrapperCol={{ span: 24 }}>
                            <SearchInput<Kunde>
                                popupMatchSelectWidth={1000}
                                value={props.currentVertragKost.versorger}
                                label={props.currentVertragKost.versorger?.name}
                                valueProperty={'id'}
                                fetchDataRequest={KundenService.getAllVersorger}
                                onValueChange={(value) => handleVertragKostChange('versorger', value)}
                                menuHeader={<Row align="middle" justify="center">
                                    <Col span={12}><p>Kunde</p></Col>
                                    <Col span={10}><p>Adresse</p></Col>
                                    <Col span={2}><p>Kunde von</p></Col>
                                </Row>}
                                labelFormatter={customer => [fmt.kunde(customer), fmt.adresse(customer.adressen?.at(0))].filter(hasText).join(', ')}
                                render={(customer) => <Row>
                                        <Col span={12}>{fmt.kunde(customer)}</Col>
                                        <Col span={10}>{fmt.adresse(customer.adressen?.[0])}</Col>
                                        <Col span={2}>{fmt.mandant(customer)}</Col>
                                    </Row>}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item label={' '} labelCol={{ span: 12, offset: 8 }}>
                            <Button ghost type="primary" href="/#/customer?branche=28" target="_blank"><PlusOutlined></PlusOutlined></Button>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item required label="Tarifname" wrapperCol={{ span: 22 }}>
                            <SearchAutoComplete
                                popupMatchSelectWidth={1000}
                                allowClear={false}
                                value={props.currentVertragKost.tarifname}
                                onValueChange={(value) => handleVertragKostChange('tarifname', value)}
                                property={'id'}
                                handleSelect={(value) => {
                                    props.setCurrentVertragKost(prev => {
                                        let newValue = { ...prev };
                                        newValue.versorger = value.versorger;
                                        newValue.tarifname = value.tarifname;
                                        newValue.arbeitspreis = value.arbeitspreis;
                                        newValue.grundpreis = value.grundpreis;
                                        newValue.quelle = value.quelle;
                                        newValue.preisstand = value.preisstand;
                                        newValue.htap = value.htap;
                                        newValue.ntap = value.ntap;
                                        return newValue;
                                    });
                                }}
                                fetchDateRequest={ZaehlerstanderfassungService.getVertragKost}
                                render={(vertrag) =>
                                ({
                                    value: vertrag.id,
                                    key: vertrag.id,
                                    label: (
                                        <Row align="middle" justify="center" gutter={[8, 8]}>
                                            <Col span={6}>{vertrag.versorger?.name}</Col>
                                            <Col span={6}>{vertrag.tarifname}</Col>
                                            <Col span={5}>{vertrag.grundpreis}</Col>
                                            <Col span={5}>{vertrag.arbeitspreis}</Col>
                                            <Col span={2}>{fmt.datum(vertrag.preisstand)}</Col>
                                        </Row>
                                    ),
                                })
                                }
                                menuHeader={<Row align="middle" justify="center" gutter={[8, 8]}>
                                    <Col span={6}>Versorger</Col>
                                    <Col span={6}>Tarifname</Col>
                                    <Col span={5}>Grundpreis</Col>
                                    <Col span={5}>Arbeitspreis</Col>
                                    <Col span={2}>Preisstand</Col>
                                </Row>}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item required label="Preisstand" wrapperCol={{ span: 22 }}>
                            <Input type="date" value={props.currentVertragKost.preisstand} onChange={e => handleVertragKostChange('preisstand', e.target.value)}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item required label="Quelle" wrapperCol={{ span: 22 }}>
                            <Select value={props.currentVertragKost.quelle} onChange={value => handleVertragKostChange('quelle', value)}>
                                <Option value={1} key={1}>Check24</Option>
                                <Option value={2} key={2}>Verivox GmbH</Option>
                                <Option value={3} key={3}>Versorger</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>
}

export default EditVertragInfo