import dayjs from "dayjs";
import { numberOrZero } from "../utils/ts_helpers";
import {
    Energieart,
    Netzentgelte,
    VertragsKost,
    Zaehlerstanderfassung,
} from "./api-types";

export const calcEnergieKosten = (
    vertragKost: VertragsKost,
    zaehlerstanderfassung: Zaehlerstanderfassung,
) => {
    const netzentgelte = calcNetzentgelte(vertragKost, zaehlerstanderfassung);
    if (netzentgelte !== undefined) {
        return (
            calcGesamtKosten(vertragKost, zaehlerstanderfassung) -
            calcFixKosten(netzentgelte) -
            (calcVariableKosten(netzentgelte, zaehlerstanderfassung) / 100)
        );
    } else {
        return 0;
    }
};

export const calcEnergiePreis = (
    vertragKost: VertragsKost,
    zaehlerstanderfassung: Zaehlerstanderfassung,
) => {
    if (
        calcEnergieKosten(vertragKost, zaehlerstanderfassung) &&
        zaehlerstanderfassung.lieferstelle.verbrauch
    ) {
        return calcEnergieKosten(vertragKost, zaehlerstanderfassung) /
            zaehlerstanderfassung.lieferstelle.verbrauch * 100;
    } else {
        return 0;
    }
};

export const calcFixKosten = (netzentgelte: Netzentgelte) => {
    if (netzentgelte.grundpreis) {
        return (numberOrZero(netzentgelte.grundpreis) +
            numberOrZero(netzentgelte.messstellenbetrieb) +
            numberOrZero(netzentgelte.messung) +
            numberOrZero(netzentgelte.abrechnung) +
            numberOrZero(netzentgelte.spitzenpreis));
    }
    return 0;
};

export const calcGesamtKosten = (
    vertragKost: VertragsKost,
    zaehlerstanderfassung: Zaehlerstanderfassung,
) => {
    let result = 0;

    if (
        vertragKost.grundpreis && vertragKost.arbeitspreis &&
        zaehlerstanderfassung.lieferstelle.verbrauch
    ) {
        result = numberOrZero(vertragKost.grundpreis) +
            zaehlerstanderfassung.lieferstelle.verbrauch *
                numberOrZero(vertragKost.arbeitspreis) / 100;
    }

    return result;
};

export const calcVariableKosten = (
    netzentgelte: Netzentgelte,
    zaehlerstanderfassung: Zaehlerstanderfassung,
) => {
    let result = 0;

    if (
        zaehlerstanderfassung.lieferstelle.verbrauch &&
        netzentgelte.arbeitspreis
    ) {
        if (
            zaehlerstanderfassung.lieferstelle.energieartId === Energieart.GAS
        ) {
            result = zaehlerstanderfassung.lieferstelle.verbrauch * (
                numberOrZero(netzentgelte.arbeitspreis) +
                numberOrZero(netzentgelte.konzessionsabgabe) +
                numberOrZero(netzentgelte.bilanzierungsumlage) +
                numberOrZero(netzentgelte.gasspeicherumlage) +
                numberOrZero(netzentgelte.konvertierungsumlage) +
                numberOrZero(netzentgelte.erdgassteuer) +
                numberOrZero(netzentgelte.co2preis)
            );
        } else {
            result = zaehlerstanderfassung.lieferstelle.verbrauch *
                    numberOrZero(netzentgelte.arbeitspreis) +
                (numberOrZero(netzentgelte.abschaltbarelasten) +
                        numberOrZero(netzentgelte.eeg) +
                        numberOrZero(netzentgelte.ka) +
                        numberOrZero(netzentgelte.kwk) +
                        numberOrZero(netzentgelte.nev) +
                        numberOrZero(netzentgelte.stromsteuer) +
                        numberOrZero(netzentgelte.offshore)) *
                    zaehlerstanderfassung.lieferstelle.verbrauch;
        }
    }

    return result;
};

const calcNetzentgelte = (
    vertragKost: VertragsKost,
    zaehlerstanderfassung: Zaehlerstanderfassung,
) => {
    const year = vertragKost.preisstand?.substring(0, 4);
    const candidateList = zaehlerstanderfassung.netzentgeltList
        .filter((n) => n.id.preisstand.substring(0, 4) === year)
        .sort((a, b) =>
            Math.abs(
                dayjs(a.id.preisstand).diff(dayjs(vertragKost.preisstand)),
            ) -
            Math.abs(dayjs(b.id.preisstand).diff(dayjs(vertragKost.preisstand)))
        );
    return candidateList.at(0);
};

const rlmService = {
    calcEnergieKosten,
    calcEnergiePreis,
    calcFixKosten,
    calcGesamtKosten,
    calcVariableKosten,
};

export default rlmService;
