import { CloseCircleOutlined } from "@ant-design/icons";
import { Col, List, Radio, Row, Tag } from "antd";
import { useState } from "react";
import { Bank, Bankverbindung, Bindung, Kontakt, KundenAdresse, Vermittler } from "../../services/api-types";

type ItemType = string | number | boolean | Vermittler | number[] | Bankverbindung[] | Bindung | Kontakt[] | KundenAdresse[] | Bank | undefined;

interface ListRadioItemProps<T> {
    value1?: T;
    value2?: T;
    result?: T;
    isEqual: (v1?: T, v2?: T) => boolean;
    formatLabel: (t?: T) => JSX.Element | string;
    itemName?: string;
    handleValueChange: (value: T) => void;
};

function ListRadioItem<T extends ItemType>({ value1, value2, isEqual, formatLabel, result, itemName, handleValueChange }: ListRadioItemProps<T>): JSX.Element {
    const equal = isEqual(value1, value2);
    const [value1Checked, setValue1Checked] = useState(false);
    const [value2Checked, setValue2Checked] = useState(false);
    return <List.Item>
        <Row gutter={[8, 8]} justify='center' align='middle'>
            <Col span={1}>{equal || value1Checked || value2Checked ? '' : <Tag icon={<CloseCircleOutlined />} color={'error'}></Tag>}</Col>
            <Col span={3}><b>{itemName}</b></Col>
            <Col span={12}>
                {
                    equal ?
                        <Row gutter={[8, 8]} justify='start' align='middle'>
                            <Col span={11} style={{ marginLeft: 24 }}>{formatLabel(value1)}</Col>
                            <Col span={11} style={{ marginLeft: 32 }}>{formatLabel(value2)}</Col>
                        </Row>
                        :
                        <Radio.Group style={{ width: '100%' }} defaultValue={result} onChange={e => handleValueChange(e.target.value)}>
                            <Row gutter={[8, 8]} justify='start' align='middle'>
                                <Col span={12}><Radio value={value1} checked={value1Checked} onChange={e => setValue1Checked(e.target.checked)}>{formatLabel(value1)}</Radio></Col>
                                <Col span={12}><Radio value={value2} checked={value2Checked} onChange={e => setValue2Checked(e.target.checked)}>{formatLabel(value2)}</Radio></Col>
                            </Row>
                        </Radio.Group>
                }
            </Col>
            <Col span={6}>{formatLabel(result)}</Col>
        </Row>
    </List.Item>
}

export default ListRadioItem