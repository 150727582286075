import { Col, Form, Input, Modal, Row, Select } from "antd";
import { Dispatch, SetStateAction, useRef } from "react";
import Draggable from "react-draggable";
import { EditMode } from "../../pages/Customer/Customer";
import { Kontakt, KontaktArt, LookupLists } from "../../services/api-types";
import { telefonnummer } from "../../utils/formatter";

interface EditKontaktProps {
    kontakt: Kontakt
    onKontaktChange: Dispatch<SetStateAction<Kontakt>>
    lookup: LookupLists
    mode: EditMode
    visible: boolean
    onCancel: () => void
    onSubmit: () => void
    landCode: string
}
export function EditKontakt({
    kontakt,
    lookup,
    onKontaktChange,
    mode,
    visible,
    onCancel,
    onSubmit,
    landCode
}: EditKontaktProps) {
    const { Option } = Select;

    const handleContactChange = <K extends keyof Kontakt>(prop: K, value: Kontakt[K] | null) => {
        onKontaktChange(prev => {
            let contact = Object.assign({}, prev);
            if (value === null) {
                delete contact[prop];
            } else {
                contact[prop] = value;
            }
            return contact;
        });
    };

    //If running in React Strict mode, ReactDOM.findDOMNode() is deprecated.
    const nodeRef = useRef(null);

    return (
        <Modal destroyOnClose={true}
            maskClosable={false}
            width={450}
            title={mode === EditMode.EDIT_EXISTING ? 'Kontakt Bearbeiten' : 'Neue Kontaktdaten'}
            open={visible}
            onCancel={onCancel}
            onOk={onSubmit}
            okText={mode === EditMode.EDIT_EXISTING ? 'Aktualisieren' : 'Hinzufügen'}
            modalRender={modal => (
                <Draggable nodeRef={nodeRef} handle=".ant-modal-header">
                    <div ref={nodeRef}>{modal}</div>
                </Draggable>
            )}>
            <Form layout='vertical'>
                <Row>
                    <Col span={24}>
                        <Form.Item label='Kontaktart'>
                            {
                                <Select value={kontakt.kontaktartId} onChange={value => { handleContactChange('kontaktartId', value); }}>
                                    {lookup.kontaktartList.map(ka => <Option key={ka.id} value={ka.id}>{ka.kontaktart}</Option>)}
                                </Select>
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label='Kontaktdaten'>
                            {
                                Kontakt.isPhoneLike(kontakt.kontaktartId) ?
                                    <Input value={kontakt.inhalt} onChange={e => handleContactChange('inhalt', telefonnummer(landCode).print(e.target.value.substring(0, 16)))} /> :
                                    kontakt.kontaktartId === KontaktArt.HOMEPAGE ?
                                        <Input value={kontakt.inhalt} onChange={e => {
                                            let value = e.target.value;
                                            if (value.length >= 4 && !value.startsWith('http')) {
                                                value = 'http://' + value;
                                            }
                                            handleContactChange('inhalt', value);
                                        }} />
                                        :
                                        <Input value={kontakt.inhalt} onChange={e => handleContactChange('inhalt', e.target.value)} />
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label='Bezeichnung'>
                            <Select value={kontakt.bezeichnung} onChange={value => {
                                handleContactChange('bezeichnung', lookup.kontaktBezeichnungList.find(kb => kb.kontakt_bezeichnung === value)?.kontakt_bezeichnung);
                            }}>
                                {
                                    kontakt.kontaktartId === KontaktArt.EMAIL ? lookup.kontaktBezeichnungList.map(kb => <Option key={kb.id} value={kb.kontakt_bezeichnung}>{kb.kontakt_bezeichnung}</Option>)
                                    : lookup.kontaktBezeichnungList.filter(kb => kb.id !== 3).map(kb => <Option key={kb.id} value={kb.kontakt_bezeichnung}>{kb.kontakt_bezeichnung}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}