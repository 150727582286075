import { Link } from "react-router-dom";

const Missing = () => {
    return (
        <article style={{ padding: "100px" }}>
            <h1>Oops!</h1>
            <p>404 Not Found</p>
            <div>
                <Link to="/start">Zurück zur Startseite</Link>
            </div>
        </article>
    )
}

export default Missing;