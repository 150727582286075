import { Kunde } from '../services/api-types';


export namespace FrontendId {
    export type Type = string & { readonly __tag: unique symbol }
    export const fromKunde = (person: Kunde) => (person.id !== undefined
        ? `${person.id}`
        : person.vorname + '@' + person.name) as Type

    export const keys = (o: { [frontendId: Type]: unknown }) => Object.keys(o).map(k => k as Type)
}


export namespace BindungId {
    export type Type = string & { readonly __tag: unique symbol }
    export const fromKunden = (source: Kunde, target: Kunde) => (FrontendId.fromKunde(source) + '#' + FrontendId.fromKunde(target)) as BindungId.Type
    export const getTarget = (id: Type) => (id.split('#')[1]) as FrontendId.Type
    export const getSource = (id: Type) => (id.split('#')[0]) as FrontendId.Type

    export const keys = (o: { [bindungId: Type]: unknown }) => Object.keys(o).map(k => k as Type)
}
