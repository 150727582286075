import { Button, Image, Layout, Menu, Tag } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import AuthencationService from "../../services/AuthencationService";
import TokenService from "../../services/TokenService";
import { handleBeforeunloadEvent } from '../../utils/ts_helpers';

export default function Topbar() {

    const auth = TokenService.getAuth();

    const navigate = useNavigate();

    const location = useLocation();

    const logout = () => {
        window.removeEventListener('beforeunload', handleBeforeunloadEvent);
        AuthencationService.logout();
        navigate('/login', { state: { from: location }, replace: true });
    }

    const { Header } = Layout;

    return (
        <Header style={{ display: 'flex', padding: '0 16px' }}>
            <Image src="/images/Logo.png" width={200} alt="STI Logo" />
            <Menu mode="horizontal" style={{ flex: 1, justifyContent: 'flex-end' }}>
                {auth &&
                    <div style={{ marginLeft: 'auto' }}>
                        <Tag color="#f00">{auth.username}</Tag>
                        <Button onClick={() => { logout(); }}>Abmelden</Button>
                    </div>
                }
            </Menu>
        </Header>
    )
}