import { AutoComplete, Col, Form, Input, Modal, Row, Select } from "antd";
import React, { useState } from "react";
import Draggable from "react-draggable";
import { EditMode } from "../../pages/Customer/Customer";
import CustomerAddressService from "../../services/CustomerAddressService";
import { KundenAdresse, lookup } from "../../services/api-types";
import { hasText } from "../../utils/ts_helpers";

// TODO this function could be moved closer to the definition of
const updateAddressField = <K extends keyof KundenAdresse>(
    prop: K,
    value: KundenAdresse[K] | null,
    address: KundenAdresse): KundenAdresse => {
    let newAddress = Object.assign({}, address);
    if (value === null) {
        delete newAddress[prop];
    } else {
        newAddress[prop] = value;
    }
    return newAddress;
};


interface EditAdresseProps {
    address: KundenAdresse
    lookupAdressartList: lookup.Adressart[]
    onAddressChange: (adresse: KundenAdresse) => void
    mode: EditMode
    visible: boolean
    onCancel: () => void
    onSubmit: () => void
}

export function EditAdresse({
    address,
    lookupAdressartList,
    onAddressChange,
    mode,
    visible,
    onCancel,
    onSubmit
}: EditAdresseProps) {

    const [lookupStreets, setLookupStreets] = useState<{ label: string, value: string }[]>([]);

    const getLookupStreets = (street: string) => {
        if (street) {
            CustomerAddressService.getAllAddresesByStreet(street).then(res => {
                setLookupStreets(res.filter((v, i, a) => a.indexOf(v) === i).map(d => { return { label: d, value: d }; }));
            });
        }
    };

    const [lookupLocations, setLookupLocations] = useState<{ label: string, value: string }[]>([]);

    const getLookupLocations = (location: string) => {
        if (location) {
            CustomerAddressService.getAllLocationsByLocation(location).then(res => {
                setLookupLocations(res.filter((v, i, a) => a.indexOf(v) === i).map(d => { return { label: d, value: d }; }))
            });
        }
    };

    const [lookupDistricts, setLookupDistricts] = useState<{ label: string, value: string }[]>([]);

    const getLookupDistricts = (location: string) => {
        if (location) {
            CustomerAddressService.getAllDistrictsByLocation(location).then(res => {
                setLookupDistricts(res.filter(s => hasText(s)).filter((v, i, a) => a.indexOf(v) === i).map(d => { return { label: d, value: d }; }))
            });
        }
    };

    const isEdit = mode === EditMode.EDIT_EXISTING;

    const handleAddressChange = <K extends keyof KundenAdresse>(prop: K, newValue: KundenAdresse[K] | null) =>
        onAddressChange(updateAddressField(prop, newValue, address))

    //If running in React Strict mode, ReactDOM.findDOMNode() is deprecated.
    const nodeRef = React.useRef(null);

    return (
        <Modal
            destroyOnClose={true}
            maskClosable={false}
            width={300}
            title={isEdit ? 'Adresse Bearbeiten' : 'Neue Adresse'}
            open={visible}
            onOk={onSubmit}
            okText={isEdit ? 'Aktualisieren' : 'Hinzufügen'}
            onCancel={onCancel}
            modalRender={modal => (
                <Draggable nodeRef={nodeRef} handle=".ant-modal-header">
                    <div ref={nodeRef}>{modal}</div>
                </Draggable>
            )}>
            <Form layout='vertical'>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            initialValue={address.strasse}
                            label="Straße"
                            name='strasse'
                            rules={[{
                                message: 'Straßennamen muss mit einem Großbuchstaben beginnen.',
                                pattern: /^\p{Uppercase}/u
                            }]}>
                            <AutoComplete
                                options={lookupStreets}
                                value={address.strasse}
                                onChange={value => {
                                    getLookupStreets(value);
                                    if ((value.match(/.*str\.?$/) || value.endsWith('strasse'))) {
                                        value = value.replace(/str\.?/, 'straße').replace('strasse', 'straße');
                                    }
                                    if (value.match(/.*Str\.?$/) || value.endsWith('Strasse')) {
                                        value = value.replace(/Str\.?/, 'Straße').replace('Strasse', 'Straße');
                                    }
                                    handleAddressChange('strasse', value);
                                }}
                                onSelect={(value: string | null) =>
                                    handleAddressChange('strasse', value)
                                }>
                            </AutoComplete>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Hausnr">
                            <Input value={address.hausnr} onChange={e => handleAddressChange('hausnr', e.target.value)}></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            initialValue={address.plz}
                            label="PLZ"
                            name={'PLZ'}
                            rules={[{
                                len: 5,
                                max: 5,
                                message: 'Bitte eine gültige PLZ eingeben.',
                                min: 5,
                                pattern: /^[0-9]{5}$/
                            }]}>
                            <Input value={address.plz} maxLength={5} onChange={e => handleAddressChange('plz', e.target.value)}></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            initialValue={address.ort}
                            label="Ort / Stadt"
                            name={'ort'}
                            rules={[{
                                message: 'Ort/Stadt muss mit einem Großbuchstaben beginnen.',
                                pattern: /^\p{Uppercase}/u
                            }]}>
                            <AutoComplete
                                options={lookupLocations}
                                value={address.ort}
                                onChange={value => { getLookupLocations(value); getLookupDistricts(value); handleAddressChange('ort', value); }}
                                onSelect={(value: string | null) => { handleAddressChange('ort', value); }}>
                            </AutoComplete>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            initialValue={address.ortsteil}
                            label="Ortsteil / Stadtteil"
                            name={'ortsteil'}
                            rules={[{
                                message: 'Ortsteil/Stadtteil muss mit einem Großbuchstaben beginnen.',
                                pattern: /^\p{Uppercase}/u
                            }]}>
                            <AutoComplete
                                options={lookupDistricts}
                                value={address.ort}
                                onChange={value => { handleAddressChange('ortsteil', value); }}
                                onSelect={(value: string | null) => { handleAddressChange('ortsteil', value); }}>
                            </AutoComplete>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Land"
                            name={'land'}
                            rules={[{
                                message: 'Ländernamen muss mit einem Großbuchstaben beginnen.',
                                pattern: /^\p{Uppercase}/u
                            }]}>
                            <Input value={address.land} defaultValue={'Deutschland'} onChange={e => handleAddressChange('land', e.target.value)}></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Adressart"
                            name={'adressart'}
                        >
                            <Select
                                value={address.adressart}
                                defaultValue={address.adressart}
                                onChange={value => {
                                    handleAddressChange('adressart', value);
                                }}
                                options={lookupAdressartList.map(la => ({ value: la.id, label: la.adressart }))}
                            ></Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}