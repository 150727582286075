import { Bankverbindung } from './api-types';
import STIClient from "./STIClient";

const BANKVERBINDUNG_REST_BASE_URL = '/bankverbindung';

class BankverbindungService {
    async getAllBankverbindunge(): Promise<Bankverbindung[]> {
        const res = await STIClient.get(BANKVERBINDUNG_REST_BASE_URL, {
            method: 'GET',
        });
        return res.data;
    }

    async createNewBankverbindung(Bankverbindung: Bankverbindung): Promise<Bankverbindung> {
        const res = await STIClient.post(BANKVERBINDUNG_REST_BASE_URL + '/new', Bankverbindung);
        return res.data;
    }

    async getBankverbindungById(id: number): Promise<Bankverbindung> {
        const res = await STIClient.get(BANKVERBINDUNG_REST_BASE_URL + '/' + id);
        return res.data;
    }

    async updateBankverbindung(Bankverbindung: Bankverbindung, id: number): Promise<Bankverbindung> {
        const res = await STIClient.put(BANKVERBINDUNG_REST_BASE_URL + '/' + id, Bankverbindung);
        return res.data;
    }

    async deleteBankverbindung(id: number) {
        return await STIClient.delete(BANKVERBINDUNG_REST_BASE_URL + '/' + id);
    }
}

export type { Bankverbindung };

const bankverbindungService = new BankverbindungService();

export default bankverbindungService;