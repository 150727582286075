import { Input, InputProps, InputRef } from "antd";
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Formatter } from '../../utils/formatter';

interface InputFormattedProps<T> extends Omit<InputProps, 'value' | 'onChange'> {
    formatter: Formatter<T>
    onChange?: (newValue: T) => void
    value?: T;
}

/**
 * Wrapper for antd Input component that uses a formatter to change the presentation of the value.
 *
 * Currently, it only updates and formats the value on blur events, this will probably be
 * enhanced in the future.
 *
 * If maxLength is used, it should be sufficient for the formatted version of the value.
 */
export function InputFormatted<T>({
    formatter,
    onChange,
    value,
    ...rest
}: InputFormattedProps<T>) {

    const [cursor, setCursor] = useState<number | null>(null);
    const [cursorIsInLastPosition, setCursorIsInLastPosition] = useState(formatter.print(value).length === 0)
    const inputRef = useRef<InputRef>(null);

    useEffect(() => {
        const input = inputRef.current;
        if (input && cursor !== null) {
            const newCursor = cursorIsInLastPosition ? formatter.print(value).length : cursor;
            input.setSelectionRange(newCursor, newCursor);
        }
    }, [cursor, cursorIsInLastPosition, formatter, inputRef, value]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCursor(e.target.selectionStart);
        setCursorIsInLastPosition(e.target.selectionStart === e.target.value.length);
        onChange?.(formatter.parse(e.target.value));
    };

    return <Input
        ref={inputRef}
        value={formatter.print(value)}
        onChange={handleChange}
        {...rest}
    />

}
