/**
 * Collection of formatters for simple values
 */

import dayjs from 'dayjs';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { electronicFormatIBAN, friendlyFormatIBAN } from 'ibantools';
import { Address, Kontakt, Kunde, KundenAdresse, Lieferstelle, lookup } from "../services/api-types";
import { hasText, isPresent } from './ts_helpers';

/**
 * Formatting of values into user-facing texts
 *
 * Laws for implementations:
 * - parse(format(x)) === x for all valid x !== undefined
 */
export interface Formatter<T> {
    print: (object?: T) => string
    parse: (text: string) => T
}

export const kunde = (kunde?: Kunde): string =>
    [kunde?.name || '<unbenannt>', kunde?.vorname].filter(s => hasText(s)).join(', ')

export const kundeMitZusatz = (kunde?: Kunde) : string => 
    [kunde?.name || '<unbenannt>', kunde?.vorname, kunde?.zusatzbezeichnung].filter(s => hasText(s)).join(', ')

export const vermittler = (kunde?: Kunde) : string => 
    [kunde?.name || '', kunde?.vorname, kunde?.zusatzbezeichnung].filter(s => hasText(s)).join(', ')

export const adresse = (adresse?: KundenAdresse): string => adresse ? `${adresse.strasse} ${adresse.hausnr}, ${adresse.plz} ${adresse.ort}` : '';

export const lieferstelle = (lieferstelle?: Lieferstelle): string => {
    const result = [[lieferstelle?.strasse, lieferstelle?.hausnr].filter(isPresent).join(' '), lieferstelle?.plz, lieferstelle?.ort, lieferstelle?.ortsteil].filter(isPresent).join(', ');
    return (result === '' || !isPresent(lieferstelle)) ? '<unbekannt>' : result;
}

export const address = (address: Address) => `${address.strasse} ${address.hausnr}, ${address.plz} ${address.ort}`;

export const datum = (datum?: string): string => dayjs(datum).format('DD.MM.YYYY');

export const zeitpunkt = (value?: string): string => dayjs(value).format('DD.MM.YYYY HH:mm:ss');

export const mandant = (kunde: Kunde): string => {
    return [kunde.smartDifferent ? 'Energie' : '', kunde.smartInnovation ? 'Leasing' : ''].filter(hasText).join(', ')
}

export const round = (number: number, fractionDigits?: number): number => {
    const fixedDigits = fractionDigits ?? 2;
    return Number(number.toFixed(fixedDigits));
}

export const deutschNumberFormat = (number: number): string => {
    return new Intl.NumberFormat("DE-de").format(number);
}

export const deutschNumberParser = (val: string): number => {
    if (typeof val === "string" && !val.length) {
        val = "0.0";
    }

    const group = new Intl.NumberFormat("DE-de").format(1111).replace(/1/g, "");
    const decimal = new Intl.NumberFormat("DE-de").format(1.1).replace(/1/g, "");
    let reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
    reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");

    reversedVal = reversedVal.replace(/[^0-9.]/g, "");

    return Number.isNaN(reversedVal) ? 0 : parseFloat(reversedVal);

}

export const score = (number?: number): string => {
    return number ? number * 100 + '%' : '';
};

export const iban: Formatter<string> = {
    print: p => friendlyFormatIBAN(p) ?? '',
    parse: t => electronicFormatIBAN(t) ?? ''
}
export const blz: Formatter<string> = {
    // format 3,3,2
    print: blz => blz?.replace(/(.{3})(?!$)/g, '$1 ') ?? '',
    parse: text => text.replace(/\s/g, ''),
}

export const konto: Formatter<string> = {
    // format 4,4,2
    print: konto => konto?.replace(/(.{4})(?!$)/g, '$1 ') ?? '',
    parse: text => text.replace(/\s/g, ''),
}

export const melo: Formatter<string> = {
    // format 2,6,5,20
    print: melo => melo ? melo.replace(
        /(.{2})(.{0,6})(.{0,5})(.{0,20})/,
        (_, p1, p2, p3, p4) =>  [p1, p2, p3, p4].filter(Boolean).map(part => part.toUpperCase()).join(' ')) : '',
    parse: text => text.replace(/\s/g, ''),
}

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export const telefonnummer = (regionCode: string): Formatter<string> => {
    return {
        print: telefonnummer => {
            const result = telefonnummer ?
                phoneNumberUtil.isPossibleNumberString(telefonnummer, regionCode) ?
                    phoneNumberUtil.format(phoneNumberUtil.parseAndKeepRawInput(telefonnummer, regionCode), PhoneNumberFormat.INTERNATIONAL) : telefonnummer
                : ''
            return result
        },
        parse: text => {
            return text
        }
    }
}

export const profil = (profil?: number) => {
    switch (profil) {
        case 1: {
            return 'SLP';
        }
        case 2: {
            return 'IMS';
        }
        case 3: {
            return 'Wärmepumpe';
        }
        case 4: {
            return 'Nachtspeicher';
        }
        case 5: {
            return 'RLM';
        }
        default: {
            return 'unbekannt';
        }
    }
};

export const kontakt = (k?: Kontakt, lookup?: Array<lookup.Kontaktart>): string => {
    return k ? [lookup?.find(ka => ka.id === k.kontaktartId)?.kontaktart, k.inhalt].filter(isPresent).join(': ') : '';
}

// export const netzentgelte = (netzentgelte: Netzentgelte): string => {

//     const string = `Grundpreis: ${netzentgelte.grundpreis} €/a -
//      Arbeitspreis: ${netzentgelte.arbeitspreis + netzentgelte.htap + netzentgelte.ntap} ct/kWh -
//      Betriebskosten: ${netzentgelte.abrechnung + netzentgelte.messstellenbetrieb + netzentgelte.messung} €/a -
//      Steuer/Umlage: ${netzentgelte.ka + netzentgelte.kwk + netzentgelte.abschaltbarelasten + netzentgelte.eeg + netzentgelte.nev + netzentgelte.offshore + netzentgelte.stromsteuer} ct/kWh -
//      ${datum(netzentgelte.id.preisstand)}`;
//     return string
// }