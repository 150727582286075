import { ConfigProvider } from "antd";
import deDE from 'antd/lib/locale/de_DE';
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Missing from "./components/Missing/Missing";
import Layout from "./components/Outlet/Outlet";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import Unauthorized from "./components/Unauthorized/Unauthorized";
import Customer from "./pages/Customer/Customer";
import CustomerList from "./pages/Customer/CustomerList";
import History from "./pages/History/History";
import Login from "./pages/Login/Login";
import MergeCustomers from "./pages/MergeTool/MergeCustomers";
import { MergeTool } from "./pages/MergeTool/MergeTool";
import MeterReading from "./pages/MeterReading/MeterReading";
import OfferList from "./pages/Offer/OfferList";
import AppService from "./services/AppService";
import TokenService from "./services/TokenService";
import { AppMetadata } from "./services/api-types";

const ROLES = {
  'User': 'ROLE_USER',
  'Admin': 'ROLE_ADMIN'
}

function App() {
  const auth = TokenService.getAuth();
  
  const [profiles, setProfiles] = useState<string[]>([]);
  
  const backgroundColor = profiles.includes('staging') ? '#F9F5D7' : '#FFFFFF'; // light yellow as staging server background color 

  useEffect(() => {
    async function fetchProfile() {
      setProfiles(AppMetadata.getProfiles(await AppService.appMetadata()));
    }

    fetchProfile();
  }, []);

  const themeConfig = {
    token: {
      colorBgBase: backgroundColor,
      fontSize: 18,
    },
    components: {
      Layout: {
        bodyBg: backgroundColor,
        headerBg: backgroundColor,
        siderBg: backgroundColor,
      },
      Content: {
        bodyBg: backgroundColor,
      },
    }
  };

  return (
    <ConfigProvider theme={themeConfig} locale={deDE}>
      {
        auth ?
          <>
            <Routes>
              <Route path="/" element={<Layout />}>
                {/* public links */}
                <Route path="login" element={<Login />} />
                <Route path="unauthorized" element={<Unauthorized />} />

                {/* role based protected links for User and Admin */}
                <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Admin]} />}>
                  {/* temporarily disabled dashboard homepage. TODO: design, implement, enable dashboard again in the future. */}
                  <Route path="/" element={<CustomerList />}></Route>
                  <Route path="start" element={<CustomerList />}>
                  </Route>
                  <Route path="customers" element={<CustomerList />}>
                  </Route>
                  <Route path="customer/:id" element={<Customer />}>
                  </Route>
                  <Route path="customer" element={<Customer />}>
                  </Route>
                  <Route path="offers" element={<OfferList />}>
                  </Route>
                  <Route path="meter/:id" element={<MeterReading />}></Route>
                  <Route path="meter" element={<MeterReading />}></Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                  <Route path="history" element={<History />}>
                  </Route>
                  <Route path="merge" element={<MergeTool />}>
                  </Route>
                  <Route path="mergecustomers" element={<MergeCustomers />} ></Route>
                  <Route path="mergecustomers/:id1/:id2" element={<MergeCustomers />} ></Route>
                </Route>

                <Route path="*" element={<Missing />} />
              </Route>
            </Routes>
          </> :
          <>
            <Login />
          </>
      }
    </ConfigProvider>
  );
}

export default App;
