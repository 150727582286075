import { Col, Form, InputNumber, Row, Select } from 'antd';
import { ReactNode } from 'react';
import { KuendungsfristEinheit, KuendungsfristTyp } from '../../services/api-types';

interface KuendigungsfristProps {
    type?: KuendungsfristTyp;
    onTypeChange: (type: KuendungsfristTyp) => void;
    fristNumber?: number;
    onFristNumberChange: (frist: number | null) => void;
    fristEinheit?: KuendungsfristEinheit;
    onFristEinheitChange: (einheit: KuendungsfristEinheit) => void;
    children?: ReactNode;
};

function Kuendigungsfrist({ type, onTypeChange, fristNumber, onFristNumberChange, fristEinheit, onFristEinheitChange }: KuendigungsfristProps) {

    const { Option } = Select;

    return (
        <Row gutter={[8, 8]}>
            <Col span={8}>
                <Form.Item label='Kündigungsfrist'>
                    <Select value={type} onChange={type => onTypeChange(type)}>
                        {Object.values(KuendungsfristTyp).map(v => <Option key={v} value={v}>{v}</Option>)}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={8} hidden={type !== KuendungsfristTyp.FRIST}>
                <Form.Item label="Frist">
                    <InputNumber style={{ width: '100%' }}
                        precision={0}
                        defaultValue={0}
                        controls={false}
                        value={fristNumber}
                        onChange={value => onFristNumberChange(value)}
                        addonAfter={
                            <Select style={{ minWidth: 100 }} value={fristEinheit} onChange={value => onFristEinheitChange(value)}>
                                {Object.values(KuendungsfristEinheit).map(v => <Option key={v} value={v}>{v}</Option>)}
                            </Select>}>
                    </InputNumber>
                </Form.Item>
            </Col>
        </Row>
    )
}

export default Kuendigungsfrist