import { Typography as t } from "antd";
import { formatDistance } from "date-fns";
import { de } from 'date-fns/locale';
import { useEffect, useState } from "react";
import KundenService from "../../services/KundenService";
import { Kunde, LeascomSyncState } from "../../services/api-types";
import { isPresent } from "../../utils/ts_helpers";

export interface SyncStateProps {
    kunde: Kunde

    /** display refresh period in milliseconds; this updates e.g. the relative time since the last sync */
    refreshDisplay?: number,

    /** refresh sync state in milliseconds */
    refreshSyncState?: number
}

export const SyncState = ({kunde, refreshDisplay, refreshSyncState}: SyncStateProps) => {

    const SEC = 1000;
    const displayRefreshPeriodMillis = refreshDisplay ?? 1*SEC;
    const syncStateRefreshMillis = refreshSyncState ?? 30*SEC;

    const [state, setState] = useState<LeascomSyncState | null>(null);
    const [now, setNow] = useState(new Date());
    // a counter that is increased every time the state should be refreshed
    const [refreshState, setRefreshState] = useState(0); 
    
    useEffect(() => {
        const diplayRefreshInterval = setInterval(() => setNow(new Date()), displayRefreshPeriodMillis);
        return () => clearInterval(diplayRefreshInterval);
    }, []);

    useEffect(() => {
        const stateRefreshInterval = setInterval(() => setRefreshState(n => n+1), syncStateRefreshMillis);
        return () => clearInterval(stateRefreshInterval);
    }, []);

    useEffect(() => {
        const fetchState = async () => {
            setState(await KundenService.getSyncState(kunde.id));
        }
        fetchState().catch(err => {
            console.error(err);
            setState({
                kundeId: kunde.id ?? -1,
                message: `${err}`,
                syncResult: 'UNKNOWN',
                timestamp: new Date()
            });
        })
        
    }, [kunde.id, refreshState]);

    if (state === null) {
        return <></>
    }

    const freshness = (isPresent(state?.timestamp) && state.timestamp.getTime() < now.getTime()) 
        ? formatDistance(now, state?.timestamp, {locale:de, includeSeconds: true})
        : '';

    switch (state.syncResult) {
        case "OK": return <t.Text type="secondary">synchronisiert { prefixedWith('vor ')(freshness) }</t.Text>
        case "PARTIAL":
        case "FAILED": return <t.Text type="warning">Snychr. fehlgeschlagen { prefixedWith('vor ')(freshness) } - { state.message?? '' }</t.Text>
        case "UNSYNCED": return <t.Text type="secondary">nicht synchronisiert</t.Text>
        case "UNKNOWN": return <t.Text type="warning">Synchr.-Status unbekannt { prefixedWith('seit ')(freshness) } - { state.message?? '' }</t.Text>
        default: return <span>Illegal server response</span> // this could happen if the syncResult were validated
    }

}

const prefixedWith = (prefix: string) => (text?: string|null, ) => (isPresent(text) && text !== '') ? (prefix + text) : ''