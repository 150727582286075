import { TablePaginationConfig } from "antd";
import STIClient from "./STIClient";
import { Angebot, AngebotView, PageType, Sorter } from "./api-types";
import { isPresent } from "../utils/ts_helpers";

const ANGEBOT_REST_BASE_URL = '/angebot';
const record: Record<string, string> = {
    'objekte' : 'profil',
    'ges.Verbrauch': 'gesamtverbrauch',
    'durchschn.Energiepreis': 'durchschnittenergiepreis',
    'zählertyp': 'profil',
    'aktuellerVersorger': 'oversorger',
    'benchmarkVersorger': 'versorger',
    'zählernummer': 'zaehlernummer',
    'benchmarkDatenquelle': 'datenquelle',
    'benchmarkEnergiepreis': 'energiepreis',
};

class AngebotService {
    async createAngebot(angebot: Angebot): Promise<Angebot> {
        const res = await STIClient.post(ANGEBOT_REST_BASE_URL,
            { ...angebot, zaehlerstanderfassungen: angebot.zaehlerstanderfassungen.map(a => ({ id: { zaehlerstanderfassungId: a.id } })) });
        return res.data;
    }

    async updateAngebot(id: number, angebot: Angebot): Promise<Angebot> {
        const res = await STIClient.put(ANGEBOT_REST_BASE_URL + '/' + id, angebot);
        return res.data;
    }

    async getAngebot(id: string): Promise<Angebot> {
        const res = await STIClient.get(ANGEBOT_REST_BASE_URL + '/' + id);
        return res.data;
    }

    async deleteAngebot(id: number) {
        const res = await STIClient.delete(ANGEBOT_REST_BASE_URL + '/' + id);
        return res.data;
    }

    async getPageableOffers(params: { [key: string]: string | string[] }, columns: Array<string>, pagination: TablePaginationConfig, sorters: Sorter[], signal: AbortSignal): Promise<PageType<AngebotView>> {
        const res = await STIClient.get(ANGEBOT_REST_BASE_URL + '/globalSearch', {
            params: { ...params,
                columns: columns.filter(s => isPresent(s)).map(s => s.split(' ').map((s,i) => 
            i === 0 ? s.toLowerCase(): s.toLowerCase().charAt(0).toUpperCase() + s.toLowerCase().slice(1)).join('')).map(s => {
                return record[s] ?? s;
            }
                ).join(','), 
            currentPage: pagination.current ?? 0, pageSize: pagination.pageSize, sorters: sorters },
            paramsSerializer: params => {
                return Object.keys(params)
                    .map(key => ['sorters'].includes(key) ?
                        `${key}=${encodeURIComponent(JSON.stringify(params[key]))}` :
                        Array.isArray(params[key]) && params[key].length > 0 ?
                            params[key]?.map((p: number) => `${key}=${encodeURIComponent(p)}`).join('&') : `${key}=${encodeURIComponent(params[key])}`).join('&');
            },
            signal: signal
        });
        return res.data;
    }

    async kostenVergleichExport(angebotId: number) {
        const res = await STIClient.get(ANGEBOT_REST_BASE_URL + '/kostenvergleich/' + angebotId, { responseType: 'blob' });
        return res;
    }
};

const angebotService = new AngebotService();
export default angebotService;