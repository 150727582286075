import { Divider } from 'antd';
import { Bankverbindung, Kontakt } from '../../services/api-types';
import ListRadioItem from './ListRadioItem';

type ArrayItemType = Kontakt | Bankverbindung;

interface ListArrayItemProps<T extends Array<ArrayItemType>> {
    array1?: T;
    array2?: T;
    result?: T;
    isEqual: (v1?: ArrayItemType, v2?: ArrayItemType) => boolean;
    format: (v?: ArrayItemType) => string;
    handleValueChange: (ov?: ArrayItemType, nv?: ArrayItemType) => void;
    itemName: string;
}

const ListArrayItem = <T extends Array<ArrayItemType>>({ array1, array2, result, isEqual, format, handleValueChange, itemName }: ListArrayItemProps<T>) => {
    return <>
        <Divider orientation='left'>{itemName}</Divider>
        {
            array1?.map(a1 =>
                <ListRadioItem
                    value1={a1}
                    value2={array2?.find(a2 => isEqual(a1, a2))}
                    result={result?.find(r => isEqual(a1, r))}
                    isEqual={isEqual}
                    formatLabel={format}
                    handleValueChange={(v?: ArrayItemType) => {
                        handleValueChange(result?.find(r => isEqual(r, a1)), v);
                    }}
                />
            )
        }
        {
            array2?.filter(a2 => !array1?.find(a1 => isEqual(a1, a2)))
                .map(a2 => <ListRadioItem
                    value1={array1?.find(a1 => isEqual(a1, a2))}
                    value2={a2}
                    result={result?.find(r => isEqual(r, a2))}
                    isEqual={isEqual}
                    formatLabel={format}
                    handleValueChange={(v?: ArrayItemType) => {
                        handleValueChange(result?.find(r => isEqual(r, a2)), v);
                    }}
                />)
        }
    </>
}

export default ListArrayItem