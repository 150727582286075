import React from 'react';
import { createRoot } from 'react-dom/client';
import { createHashRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import 'typeface-roboto';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container!);
const router = createHashRouter(
  createRoutesFromElements(<Route path="/*" element={<App />} />)
)
root.render(<React.StrictMode>
  <RouterProvider router={router} />
</React.StrictMode>);