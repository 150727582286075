export interface Auth {
    username: string;
    token: string;
    refreshToken: string;
    roles: string[];
}

class TokenService {
    getLocalRefreshToken(): string | null {
        const auth: string | null = localStorage.getItem("auth");
        return auth ? JSON.parse(auth).refreshToken : null;
    }

    getLocalAccessToken(): string | null {
        const auth: string | null = localStorage.getItem("auth");
        return auth ? JSON.parse(auth).token : null;
    }

    updateLocalAccessToken(token: string) {
        const auth: string | null = localStorage.getItem("auth");
        if (auth) {
            const newAuth = { ...JSON.parse(auth), token: token }
            localStorage.setItem("auth", JSON.stringify(newAuth));
        }
    }

    getAuth(): Auth | null {
        const auth: string | null = localStorage.getItem("auth");
        return auth ? JSON.parse(auth) : null;
    }

    setAuth(auth: Auth) {
        localStorage.setItem("auth", JSON.stringify(auth));
    }

    removeUser() {
        localStorage.removeItem("auth");
    }
}

const tokenService = new TokenService();

export default tokenService;