import { Modal } from "antd";

interface ConfirmDeleteProps {
    message: string
    visible: boolean
    onOk: () => void
    onCancel: () => void
}
export function ConfirmDelete({
    message,
    visible,
    onOk,
    onCancel
}: ConfirmDeleteProps) {
    return (
        <Modal
            destroyOnClose={true}
            open={visible}
            maskClosable={false}
            okButtonProps={{ danger: true }}
            title="Löschen bestätigen?"
            onOk={onOk}
            okText={'Bestätigen'}
            onCancel={onCancel}>
            <p>{message}</p>
        </Modal>
    );
}