import { Kontakt } from './api-types';
import STIClient from "./STIClient";

const KONTAKT_REST_BASE_URL = '/kontakt';

class KontaktService {
    async getAllKontakte(): Promise<Kontakt[]> {
        const res = await STIClient.get(KONTAKT_REST_BASE_URL, {
            method: 'GET',
        });
        return res.data;
    }

    async createNewKontakt(kontakt: Kontakt): Promise<Kontakt> {
        const res = await STIClient.post(KONTAKT_REST_BASE_URL + '/new', kontakt)
        return res.data;
    }

    async getKontaktById(id: number): Promise<Kontakt> {
        const res = await STIClient.get(KONTAKT_REST_BASE_URL + '/' + id);
        return res.data;
    }

    async updateKontakt(Kontakt: Kontakt, id: number): Promise<Kontakt> {
        return await STIClient.put(KONTAKT_REST_BASE_URL + '/' + id, Kontakt);
    }

    async deleteKontakt(id: number) {
        return await STIClient.delete(KONTAKT_REST_BASE_URL + '/' + id);
    }
}

export type { Kontakt };

const kontaktService = new KontaktService();

export default kontaktService;