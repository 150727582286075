import { BankTwoTone, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Layout, Modal, Row, Select, Spin } from "antd";
import axios from "axios";
import { isValidIBAN } from "ibantools";
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { EditMode } from "../../pages/Customer/Customer";
import KundenService from "../../services/KundenService";
import { Bankverbindung, Kunde } from "../../services/api-types";
import * as fmt from "../../utils/formatter";
import { errorMessage, hasElems, hasText, implies, isMissing } from '../../utils/ts_helpers';
import { InputFormatted } from '../Form/InputFormatted';

interface EditBankverbindungProps {
    kunde: Kunde,
    bankverbindung: Bankverbindung
    onBankverbindungChange: React.Dispatch<React.SetStateAction<Bankverbindung>>
    mode: EditMode
    visible: boolean
    onCancel: () => void
    onSubmit: () => void
}

export function EditBankverbindung({
    kunde,
    bankverbindung,
    onBankverbindungChange,
    mode,
    visible,
    onCancel,
    onSubmit
}: EditBankverbindungProps) {
    const { Option } = Select;

    const isEdit = mode === EditMode.EDIT_EXISTING;

    const handleBankverbindungChange = <K extends keyof Bankverbindung>(prop: K, value: Bankverbindung[K]) => {
        if (value === null) {
            onBankverbindungChange((prev: Bankverbindung) => {
                const nbv = Object.assign({}, prev);
                delete nbv[prop];
                return nbv;
            });
        } else {
            onBankverbindungChange((prev: Bankverbindung) => {
                const nbv = Object.assign({}, prev);
                nbv[prop] = value;;
                return nbv;
            });
        }
    }

    const [lookupBanks, setLookupBanks] = useState<Kunde[]>([]);

    useEffect(() => {
        if (bankverbindung.bank) {
            KundenService.getKundeById(bankverbindung.bank).then(response => {
                setLookupBanks([...lookupBanks, response]
                    .filter(b => true
                        && implies(kunde.smartDifferent, b.smartDifferent)
                        && implies(kunde.smartInnovation, b.smartInnovation))
                    .filter((value, index, array) => array.findIndex(bank => bank.id === value.id) === index));
            }).catch(error => errorMessage(error));
        }
    }, [bankverbindung.bank]);

    const getLookupBanks = async (blz: string) => {
        try {
            if (controller) { controller.abort(); }
            setLookupBanks([]);
            const newController = new AbortController();
            setController(newController);
            const banks = 
                (await KundenService.getBankByBlz(blz, newController.signal))
                .filter(b => true
                    && implies(kunde.smartDifferent, b.smartDifferent)
                    && implies(kunde.smartInnovation, b.smartInnovation) );
            setLookupBanks(banks);
            return banks;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.warn('Abfrage ist gecancelt worden.');
            }
            else {
                errorMessage(`Oooops, Es ist irgendwie schief gelaufen. Error: ${error}`);
            }
        } finally {
            setIsSearching(false);
        }
    };

    //If running in React Strict mode, ReactDOM.findDOMNode() is deprecated.
    const nodeRef = React.useRef(null);

    const isIbanValid = isValidIBAN(bankverbindung.iban ?? '');

    /* states and functions for bank searching */

    const [isSearching, setIsSearching] = useState(false);
    const [controller, setController] = useState<AbortController>();
    const [openDropdown, setOpenDropdown] = useState(false);


    return (
        <Modal destroyOnClose={true}
            maskClosable={false}
            width={800}
            title={isEdit ? 'Bankverbindung Bearbeiten' : 'Neue Bankverbindung'}
            open={visible}
            onCancel={() => { onCancel(); }}
            onOk={() => { onSubmit(); }}
            okText={isEdit ? 'Aktualisieren' : 'Hinzufügen'}
            modalRender={modal => (
                <Draggable nodeRef={nodeRef} handle=".ant-modal-header">
                    <div ref={nodeRef}>{modal}</div>
                </Draggable>
            )}>
            <Form layout='vertical'>
                <Row>
                    <Col span={24}>
                        <Form.Item required label="IBAN">
                            <Input status={isIbanValid ? undefined : "error"}
                                value={fmt.iban.print(bankverbindung.iban)}
                                onChange={async (e) => {
                                    const value = fmt.iban.parse(e.target.value);
                                    handleBankverbindungChange('iban', value);
                                    if (value.substring(4, 12).length === 8) {
                                        setIsSearching(true);
                                        const banks = await getLookupBanks(value.substring(4, 12));
                                        if (hasElems(banks)) {
                                            handleBankverbindungChange('blz', banks.at(0)?.bank?.blz);
                                            handleBankverbindungChange('bic', banks.at(0)?.bank?.bicswift);
                                            handleBankverbindungChange('bank', banks.at(0)?.id);
                                            handleBankverbindungChange('konto', value.substring(12, 22));
                                        } else {
                                            handleBankverbindungChange('blz', undefined);
                                            handleBankverbindungChange('bic', undefined);
                                            handleBankverbindungChange('bank', undefined);
                                        }
                                    } else {
                                        handleBankverbindungChange('blz', undefined);
                                        handleBankverbindungChange('bic', undefined);
                                        handleBankverbindungChange('bank', undefined);
                                    }
                                }}></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Kommentar">
                            <Input
                                value={bankverbindung.kommentar}
                                onChange={e => handleBankverbindungChange('kommentar', e.target.value)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Row>
                            <Col span={22}>
                                <Form.Item
                                    label="Bank"
                                    tooltip={{
                                        open: isIbanValid && isMissing(bankverbindung.bank),
                                        title: "Eine zu der eingegebenen IBAN passende Bank wurde nicht in der Datenbank gefunden."
                                            + " Bitte verwenden Sie den Button auf der rechten Seite, um die Bankdaten zu erfassen."
                                    }} >
                                    <Select value={bankverbindung.bank}
                                        filterOption={(input, option) => {
                                            return option?.label?.toString()?.toLowerCase().includes(input.toLowerCase()) || false
                                        }}
                                        onClick={() => setOpenDropdown(true)}
                                        loading={isSearching}
                                        optionLabelProp="label"
                                        dropdownMatchSelectWidth={1500}
                                        dropdownRender={(menu) =>
                                            isSearching ? <Row align="middle" justify="center"><Spin size="large"></Spin></Row> :
                                                hasElems(lookupBanks) ?
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <Row align="middle" justify="center">
                                                                <Col span={9}><p>Bank</p></Col>
                                                                <Col span={12}><p>Adresse</p></Col>
                                                                <Col span={3}><p>BLZ</p></Col>
                                                            </Row>
                                                        </Col>
                                                        <Col span={24}>
                                                            {menu}
                                                        </Col>
                                                    </Row> :
                                                    <Layout>{menu}</Layout>
                                        }
                                        open={openDropdown}
                                        onChange={value => {
                                            handleBankverbindungChange('bank', value);
                                            handleBankverbindungChange('bic', lookupBanks.find(bank => bank.id === value)?.bank?.bicswift);
                                            handleBankverbindungChange('blz', lookupBanks.find(bank => bank.id === value)?.bank?.blz);
                                        }}
                                        onBlur={() => {
                                            setOpenDropdown(false);
                                        }}
                                        onSelect={(value: number) => {
                                            handleBankverbindungChange('bank', value);
                                            handleBankverbindungChange('bic', lookupBanks.find(b => b.id === value)?.bank?.bicswift!);
                                            handleBankverbindungChange('blz', lookupBanks.find(bank => bank.id === value)?.bank?.blz);
                                            setOpenDropdown(false);
                                        }}>
                                        {lookupBanks
                                            .filter(bank => false
                                                || bank.bank?.blz === bankverbindung.blz 
                                                || bank.bank?.blz === bankverbindung.iban?.substring(4, 12))
                                            .map(b =>
                                            <Option key={b.id} value={b.id} label={[b.name, fmt.adresse({ strasse: b.adressen?.at(0)?.strasse, hausnr: b.adressen?.at(0)?.hausnr, plz: b.adressen?.at(0)?.plz, ort: b.adressen?.at(0)?.ort }), b.bank?.blz].filter(hasText).join(', ')}>
                                                <Row align="middle" justify="center">
                                                    <Col span={9}>{
                                                        b.name
                                                    }
                                                    </Col>
                                                    <Col span={12}>
                                                        {fmt.adresse({ strasse: b.adressen?.at(0)?.strasse, hausnr: b.adressen?.at(0)?.hausnr, plz: b.adressen?.at(0)?.plz, ort: b.adressen?.at(0)?.ort })}
                                                    </Col>
                                                    <Col span={3}>
                                                        {b.bank?.blz}
                                                    </Col>
                                                </Row>
                                            </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={1} offset={1}>
                                <Form.Item label={<BankTwoTone title="Neue Bank" style={{ color: 'steelblue' }} />} labelCol={{ span: 10, offset: 6 }}>
                                    <Button style={{ float: 'right' }} danger={isIbanValid && isMissing(bankverbindung.bank)} href="/#/customer?branche=8&funktion=10" target="_blank" icon={<PlusOutlined title="Neue Bank hinzufügen" style={{ color: 'steelblue' }} />}></Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Kontonummer">
                            <InputFormatted
                                formatter={fmt.konto}
                                maxLength={12}
                                value={bankverbindung.konto}
                                readOnly={true}
                                onChange={value => handleBankverbindungChange('konto', value)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label="BLZ">
                            <InputFormatted
                                maxLength={10}
                                value={bankverbindung.blz}
                                readOnly={true}
                                formatter={fmt.blz}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label="BIC">
                            <Input value={bankverbindung.bic} readOnly={true}></Input>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
