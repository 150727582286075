import { Affix, Col, Divider, Row } from "antd";
import { RechtsformID } from '../../pages/Customer/Customer';
import { Kunde } from "../../services/api-types";
import { hasText } from "../../utils/ts_helpers";
import { MandantIcon } from "../Customer/MandantIcon";

interface TitleProps {
    customer1: Kunde;
    customer2: Kunde;
    title?: string;
};

const formatTitle = (customer: Kunde) => {
    if (customer.rechtsform === RechtsformID.PRIVATPERSON || customer.rechtsform === RechtsformID.EINZELFIRMA || customer.rechtsform === RechtsformID.FREIBERUFLER || customer.rechtsform === RechtsformID.EHE) {
        return [customer.vorname?.replace('Nur e-mail Versand', ''), customer.name.replace('Nur e-mail Versand', '')].filter(hasText).join(' ');
    } else {
        return customer.name;
    }
};

const Title = ({ customer1, customer2, title }: TitleProps): JSX.Element => {
    return <Affix>
        <Row gutter={[8, 8]} style={{ backgroundColor: '#ffffff' }} justify='center' align='middle'>
            <Col span={6} offset={2}><Divider>{formatTitle(customer1)} <MandantIcon customer={customer1}/></Divider></Col>
            <Col span={6}><Divider>{formatTitle(customer2)} <MandantIcon customer={customer2}/></Divider></Col>
            <Col span={6}><Divider>{title}</Divider></Col>
        </Row>
    </Affix>
}

export default Title