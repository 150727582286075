import { Col, Form, Input, Row, Select } from 'antd';
import { ReactNode } from 'react';
import { VertragspartnerwechselLieferbeginnTyp } from '../../services/api-types';

interface VertragspartnerWechselLieferbeginnProps {
    type?: VertragspartnerwechselLieferbeginnTyp;
    onTypeChange: (type: VertragspartnerwechselLieferbeginnTyp) => void;
    date?: string;
    onDateChange: (date: string) => void;
    children?: ReactNode;
};

function VertragspartnerWechselLieferbeginn({ type, onTypeChange, date, onDateChange }: VertragspartnerWechselLieferbeginnProps) {

    const { Option } = Select;

    return (
        <Row gutter={[8, 8]}>
            <Col span={8}>
                <Form.Item label="Lieferbeginn">
                    <Select value={type} onChange={value => onTypeChange(value)}>
                        {Object.values(VertragspartnerwechselLieferbeginnTyp).map(v => <Option key={v} value={v}>{v}</Option>)}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={8} hidden={type === 'baldmöglichst'}>
                <Form.Item label="Datum">
                    <Input type='date' value={date} onChange={e => onDateChange(e.target.value)}></Input>
                </Form.Item>
            </Col>
        </Row>
    )
}

export default VertragspartnerWechselLieferbeginn