import { Dispatch, SetStateAction } from "react";
import { Kunde, Mandant, Vermittler } from "../../services/api-types";
import ListRadioItem from "./ListRadioItem";

interface ListVermittlerItemProps {
    value1?: Array<Vermittler>; 
    value2?: Array<Vermittler>;
    result?: Array<Vermittler>;
    formatVermittler: (v?: Vermittler) => string;
    isVermittlerEqual: (v1?: Vermittler, v2?: Vermittler) => boolean,
    setSuggest: Dispatch<SetStateAction<Kunde>>; 
    mandant: Mandant;
    itemName: string;
};

const ListVermittlerItem = ({ value1, value2, result, formatVermittler, isVermittlerEqual, setSuggest, mandant, itemName }: ListVermittlerItemProps) => {
    return (
        <ListRadioItem
            value1={value1?.find(v => v.id?.mandant === mandant)}
            value2={value2?.find(v => v.id?.mandant === mandant)}
            result={result?.find(v => v.id?.mandant === mandant)}
            itemName={itemName}
            formatLabel={formatVermittler}
            isEqual={isVermittlerEqual}
            handleValueChange={(v?: Vermittler) => {
                if (!result?.find(nv => isVermittlerEqual(nv, v))) {
                    setSuggest(prev => {
                        let newSuggest = Object.assign({}, prev);
                        let newVermittler: Vermittler = { id: { mandant: mandant }, vermittler: v?.vermittler, untervermittler: v?.vermittler }
                        newSuggest.vermittler?.push(newVermittler);
                        return newSuggest;
                    });
                }

                if (result?.find(v => v.id?.mandant === mandant) && !v) {
                    setSuggest(prev => {
                        let newSuggest = Object.assign({}, prev);
                        newSuggest.vermittler?.splice(newSuggest.vermittler?.findIndex(v => v.id?.mandant === mandant), 1);
                        return newSuggest;
                    });
                }
            }}
        ></ListRadioItem>
    )
}

export default ListVermittlerItem