import axios, { AxiosRequestHeaders } from "axios";
import TokenService from "./TokenService";

const instance = axios.create({
    baseURL: "/",
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    config => {
        const token = TokenService.getLocalAccessToken();
        if (!config || !config.headers || !config.headers['Authorization']) {
            config = {
                ...config, headers: { ...config.headers,
                    Authorization: `Bearer ${token}` } as AxiosRequestHeaders
            }
        }
        return config;
    },
    (error) => Promise.reject(error)
);

instance.interceptors.response.use(
    res => res,
    async (err) => {
        const originalConfig = err.config;
        if (originalConfig?.url !== "/auth" && err.response) {
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const rs = await instance.post("/refresh", {
                        refreshToken: TokenService.getLocalRefreshToken(),
                    });
                    const token = rs.data.token;
                    TokenService.updateLocalAccessToken(token);
                    originalConfig.headers['Authorization'] = `Bearer ${token}`;
                    return instance(originalConfig);

                } catch (_error) {
                    console.error("Failed to refresh authentication token: ", _error);
                    TokenService.removeUser();
                    window.location.reload();
                    // TODO maybe throw a custom error here so that the UI can handle redirecting to the login page
                    throw _error;
                }
            }
        }
        if (axios.isCancel(err)) {
            throw new axios.Cancel('Abfrage ist gecancelt worden.');
        }
        throw err;
    }
);

export default instance;