import { NumberOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Modal, Row } from 'antd';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import ZaehlerstanderfassungService from '../../services/ZaehlerstanderfassungService';
import { NetzentgeltDTO, Netzentgelte } from '../../services/api-types';
import * as fmt from "../../utils/formatter";
import { hasElems, hasText } from '../../utils/ts_helpers';

interface EditNetzentgelteProps {
    open: boolean;
    openModal: () => void;
    saveModal: () => void;
    closeModal: () => void;
    currentNetzentgelte: Netzentgelte;
    setCurrentNetzentgelte: Dispatch<SetStateAction<Netzentgelte>>;
    isRLM: boolean;
    lastRecord?: Netzentgelte;
    gas: boolean;
}

function EditNetzentgelte(props: EditNetzentgelteProps) {

    const isFirstRender = useRef(true);

    const handleNetzentgelteChange = <K extends keyof Netzentgelte>(prop: K, value: Netzentgelte[K] | null) => {
        props.setCurrentNetzentgelte(prev => {
            let v = Object.assign({}, prev);
            if (value === null) {
                delete v[prop];
            } else {
                v[prop] = value;
            }
            return v;
        });
    };

    const [readyToSave, setReadyToSave] = useState(false);
    useEffect(() => {

        const cn = props.currentNetzentgelte;

        const isStromReady = !props.gas
            && (!isNaN(cn.arbeitspreis) || (!isNaN(cn.htap!) && !isNaN(cn.ntap!)))
            && !isNaN(cn.grundpreis)
            && !isNaN(cn.messstellenbetrieb)
            && !isNaN(cn.messung)
            && !isNaN(cn.abrechnung)
            && cn.ka !== undefined
            && cn.kwk !== undefined
            && cn.nev !== undefined
            && cn.offshore !== undefined
            && cn.abschaltbarelasten !== undefined
            && cn.eeg !== undefined
            && cn.stromsteuer !== undefined
            && hasText(cn.id.preisstand)
            && (cn.konzessionsabgabe === 0 || cn.konzessionsabgabe === undefined)
            && (cn.bilanzierungsumlage === 0 || cn.bilanzierungsumlage === undefined)
            && (cn.gasspeicherumlage === 0 || cn.gasspeicherumlage === undefined)
            && (cn.konvertierungsumlage === 0 || cn.konvertierungsumlage === undefined)
            && (cn.erdgassteuer === 0 || cn.erdgassteuer === undefined)
            && (cn.co2preis === 0 || cn.co2preis === undefined)
            && ((cn.spitzenpreis !== undefined && cn.spitzenpreis > 0 && props.isRLM)
                || (!props.isRLM && (cn.spitzenpreis === 0 || cn.spitzenpreis === undefined)));

        const isGasReady = props.gas
            && !isNaN(cn.arbeitspreis)
            && !isNaN(cn.grundpreis)
            && (cn.htap === 0 || cn.htap === undefined)
            && (cn.ntap === 0 || cn.ntap === undefined)
            && !isNaN(cn.messstellenbetrieb)
            && !isNaN(cn.messung)
            && !isNaN(cn.abrechnung)
            && cn.konzessionsabgabe !== undefined
            && cn.bilanzierungsumlage !== undefined
            && cn.gasspeicherumlage !== undefined
            && cn.konvertierungsumlage !== undefined
            && cn.erdgassteuer !== undefined
            && cn.co2preis !== undefined
            && hasText(cn.id.preisstand)
            && (cn.ka === 0 || cn.ka === undefined)
            && (cn.kwk === 0 || cn.kwk === undefined)
            && (cn.nev === 0 || cn.nev === undefined)
            && (cn.offshore === 0 || cn.offshore === undefined)
            && (cn.abschaltbarelasten === 0 || cn.abschaltbarelasten === undefined)
            && (cn.eeg === 0 || cn.eeg === undefined)
            && (cn.stromsteuer === 0 || cn.stromsteuer === undefined)
            && ((cn.spitzenpreis !== undefined && cn.spitzenpreis > 0 && props.isRLM)
                || (!props.isRLM && (cn.spitzenpreis === 0 || cn.spitzenpreis === undefined)));
        setReadyToSave(isStromReady || isGasReady);
    }, [props.currentNetzentgelte]);

    useEffect(() => {
        const loadTimeRelevantData = async () => {
            const preisstand = props.currentNetzentgelte.id.preisstand;
            const year = parseInt(preisstand.substring(0, preisstand.indexOf('-')));
            const data: Array<NetzentgeltDTO> = await ZaehlerstanderfassungService.getNetzentgeltDTOByYear(year, props.gas!);
            if (hasElems(data)) {
                const record = data.at(0);
                props.setCurrentNetzentgelte(prev => {
                    if (record) {

                        let result: Netzentgelte = {
                            id: prev.id,
                            grundpreis: prev.grundpreis,
                            arbeitspreis: prev.arbeitspreis,
                            messstellenbetrieb: prev.messstellenbetrieb,
                            messung: prev.messung,
                            abrechnung: prev.abrechnung,
                        };

                        if (props.gas) {
                            result = {
                                ...result,
                                konzessionsabgabe: record.konzessionsabgabe,
                                bilanzierungsumlage: record.bilanzierungsumlage,
                                gasspeicherumlage: record.gasspeicherumlage,
                                konvertierungsumlage: record.konvertierungsumlage,
                                erdgassteuer: record.erdgassteuer,
                                co2preis: record.co2preis,
                            }
                        } else {
                            result = {
                                ...result,
                                kwk: record.kwk,
                                nev: record.nev,
                                abschaltbarelasten: record.abschaltbarelasten,
                                offshore: record.offshore,
                                eeg: record.eeg,
                                stromsteuer: record.stromsteuer,
                            }
                        }

                        return result;
                    }
                    return prev;
                });
            }
        }

        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        loadTimeRelevantData();

    }, [props.currentNetzentgelte.id.preisstand]);

    return (
        <>
            Netzentgelte erfassen: <Button ghost type="primary" onClick={props.openModal}><NumberOutlined></NumberOutlined></Button>
            <Modal
                maskClosable={false}
                width={1000}
                title={props.gas ? "Netzentgelte für Gas erfassen" : "Netzentgelte für Strom erfassen"}
                open={props.open}
                onCancel={props.closeModal}
                footer={[
                    <Button key="takeover" type="default" hidden={!props.lastRecord} onClick={() => { props.setCurrentNetzentgelte(props.lastRecord ?? Netzentgelte.DEFAULT()); }}>Letzte Entgelte Übernehmen</Button>,
                    <Button key="submit" type="primary" disabled={!readyToSave} onClick={() => {
                        props.saveModal();
                        props.setCurrentNetzentgelte(Netzentgelte.DEFAULT());
                    }}>
                        Speichern
                    </Button>,
                ]}>
                <Row gutter={[8, 8]}>
                    <Col span={props.gas ? 12 : 6}>
                        <Form.Item label="Grundpreis in €/a" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={2}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.grundpreis} onChange={e => handleNetzentgelteChange('grundpreis', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={props.gas ? 12 : 6}>
                        <Form.Item label="Arbeitspreis in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={3}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.arbeitspreis} onChange={e => handleNetzentgelteChange('arbeitspreis', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={6} hidden={props.gas}>
                        <Form.Item label="HT Arbeitspreis in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={3}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.htap} onChange={e => handleNetzentgelteChange('htap', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={6} hidden={props.gas}>
                        <Form.Item label="NT Arbeitspreis in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={3}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.ntap} onChange={e => handleNetzentgelteChange('ntap', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Messstellenbetrieb in €/a" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={2}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.messstellenbetrieb} onChange={e => handleNetzentgelteChange('messstellenbetrieb', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Messung in €/a" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={2}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.messung} onChange={e => handleNetzentgelteChange('messung', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Abrechnung in €/a" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={2}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.abrechnung} onChange={e => handleNetzentgelteChange('abrechnung', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={12} hidden={props.gas}>
                        <Form.Item label="KA in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={3}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.ka} onChange={e => handleNetzentgelteChange('ka', e)}></InputNumber>
                        </Form.Item>
                    </Col>

                    <Col span={12} hidden={props.gas}>
                        <Form.Item label="KWK in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={3}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.kwk} onChange={e => handleNetzentgelteChange('kwk', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={12} hidden={props.gas}>
                        <Form.Item label="§19NEV in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={3}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.nev} onChange={e => handleNetzentgelteChange('nev', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={12} hidden={props.gas}>
                        <Form.Item label="Offshore in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={3}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.offshore} onChange={e => handleNetzentgelteChange('offshore', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={12} hidden={props.gas}>
                        <Form.Item label="abschaltbare Lasten in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={3}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.abschaltbarelasten} onChange={e => handleNetzentgelteChange('abschaltbarelasten', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={12} hidden={props.gas}>
                        <Form.Item label="EEG in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={3}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.eeg} onChange={e => handleNetzentgelteChange('eeg', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={12} hidden={props.gas}>
                        <Form.Item label="Stromsteuer in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={3}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.stromsteuer} onChange={e => handleNetzentgelteChange('stromsteuer', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={12} hidden={!props.gas}>
                        <Form.Item label="Konzessionsabgabe in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={3}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.konzessionsabgabe} onChange={e => handleNetzentgelteChange('konzessionsabgabe', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={12} hidden={!props.gas}>
                        <Form.Item label="Bilanzierungsumlage in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={3}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.bilanzierungsumlage} onChange={e => handleNetzentgelteChange('bilanzierungsumlage', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={12} hidden={!props.gas}>
                        <Form.Item label="Gasspeicherumlage in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={3}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.gasspeicherumlage} onChange={e => handleNetzentgelteChange('gasspeicherumlage', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={12} hidden={!props.gas}>
                        <Form.Item label="Konvertierungsumlage in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={3}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.konvertierungsumlage} onChange={e => handleNetzentgelteChange('konvertierungsumlage', e)}></InputNumber>
                        </Form.Item>
                    </Col><Col span={12} hidden={!props.gas}>
                        <Form.Item label="Erdgassteuer in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={3}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.erdgassteuer} onChange={e => handleNetzentgelteChange('erdgassteuer', e)}></InputNumber>
                        </Form.Item>
                    </Col><Col span={12} hidden={!props.gas}>
                        <Form.Item label="CO2-Preis in ct/kWh" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={3}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.co2preis} onChange={e => handleNetzentgelteChange('co2preis', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={12} hidden={!props.isRLM}>
                        <Form.Item label="Spitzenpreis in Euro/kW/Jahr" wrapperCol={{ span: 22 }}>
                            <InputNumber decimalSeparator=","
                                precision={2}
                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                style={{ width: '100%' }} controls={false} value={props.currentNetzentgelte?.spitzenpreis} onChange={e => handleNetzentgelteChange('spitzenpreis', e)}></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Preisstand" wrapperCol={{ span: 22 }}>
                            <Input type="date" value={props.currentNetzentgelte?.id.preisstand} onChange={e => {
                                let newValue = Object.assign({}, props.currentNetzentgelte);
                                const newId = {
                                    ...newValue.id,
                                    preisstand: e.target.value,
                                };
                                handleNetzentgelteChange('id', newId);
                            }}></Input>
                        </Form.Item>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default EditNetzentgelte