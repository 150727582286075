import { FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import TokenService from "../../services/TokenService";

type RequireAuthAllowRoles = {
    allowedRoles: string[];
}

const RequireAuth: FC<RequireAuthAllowRoles> = ({ allowedRoles }) => {
    const auth = TokenService.getAuth();
    const location = useLocation();

    return (
        auth?.roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : auth?.username
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;