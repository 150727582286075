import { Divider, Empty } from 'antd'

const ListEmptyArrayItem = ({ itemName }: { itemName: string }) => {
    return (<>
        <Divider orientation='left'>{itemName}</Divider>
        <Empty></Empty>
    </>
    )
}

export default ListEmptyArrayItem