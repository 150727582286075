import { Audit, CheckHistoryResult, HistoryTableRequestConfig, PageType } from './api-types';
import STIClient from "./STIClient";

const HISTORY_REST_BASE_URL = '/history';

class HistoryService {
    async getAllKunden(): Promise<Audit[]> {
        const res = await STIClient.get(HISTORY_REST_BASE_URL + '/all', {
            method: 'GET',
        });
        return res.data;
    }

    async getPage(tableRequestConfig: HistoryTableRequestConfig, signal: AbortSignal): Promise<PageType<Audit>> {
        const res = await STIClient.get(HISTORY_REST_BASE_URL + '/page', {
            params: tableRequestConfig, paramsSerializer: params => {
                return Object.keys(params)
                    .map(key => params[key] === undefined ? `${key}=` : `${key}=${params[key]}`)
                    .join('&')
            },
            signal: signal,
        });
        return res.data;
    }

    async check(tablename: string, id: number): Promise<CheckHistoryResult> {
        const res = await STIClient.get(HISTORY_REST_BASE_URL + '/check?tablename=' + tablename + '&id=' + id);
        return res.data;
    }
}

const historyService = new HistoryService();

export default historyService;
