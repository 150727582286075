import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageHistory = () => {

  const HISTROY_STACK = "history_stack";
  const savedHistoryStackJSON = sessionStorage.getItem(HISTROY_STACK);

  const [historyStack, setHistoryStack] = useState<string[]>(savedHistoryStackJSON ? JSON.parse(savedHistoryStackJSON) : []);
  const location = useLocation();

  useEffect(() => {
    const newHistoryStack = [...historyStack, location.pathname];
    sessionStorage.setItem(HISTROY_STACK, JSON.stringify(newHistoryStack));
    setHistoryStack(newHistoryStack);
  }, [location]);

  return historyStack;
};

export default usePageHistory;