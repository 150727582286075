import { Kontakt } from '../../services/api-types';
import * as fmt from '../../utils/formatter';

interface KontaktInhaltProps {
    kontakt: Kontakt;
    landcode?: string;
}

/**
 * Renders the contents of a contact with appropriate hyperlinks
 */
export const KontaktInhalt = ({ kontakt, landcode }: KontaktInhaltProps) => {
    const value = kontakt.inhalt;

    if (Kontakt.isEMail(kontakt.kontaktartId)) {
        return <a href={'mailto:' + kontakt.inhalt}>{value}</a>
    }

    if (Kontakt.isUrl(kontakt.kontaktartId)) {
        return <a href={value} target='_blank' rel='noreferrer'>{value}</a>
    }

    if (Kontakt.isPhoneLike(kontakt.kontaktartId)) {
        const land = landcode ?? 'de';
        return fmt.telefonnummer(land).print(kontakt.inhalt)
    }

    return <>{value ?? ''}</>;

}