import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <section>
            <h1>Unauthorized</h1>
            <br />
            <p>Sie haben keinen Zugriff auf die angeforderte Seite.</p>
            <div>
                <button onClick={goBack}>Zurück</button>
            </div>
        </section>
    )
}

export default Unauthorized;